import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TrueIdAPI from "../../../../../apiConfig/TrueIdAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  balance: null,
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const fetchAsyncTruePointsBalance = createAsyncThunk(
  "coins/fetchAsyncTruePointsBalance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await TrueIdAPI.get('/api/trueid/points-balance');
      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  }
);

//Slices
const ConvertPoints = createSlice({
  name: "convertPoints",
  initialState,
  reducers: {
    resetTruePointsBalance: (state) => {
      state.balance = null;
      state.errorMessage = null;
      state.status = status.INITIAL;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncTruePointsBalance.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncTruePointsBalance.fulfilled, (state, { payload }) => {
        if (payload.data === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.balance = payload.data?.mainPointsBalance;
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncTruePointsBalance.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data?.errorMessage;
        state.status = status.ERROR;
      });
  },
});

export const { resetTruePointsBalance } = ConvertPoints.actions;

export default ConvertPoints.reducer;
