import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  collections: [],
  tokens: {},
  status: status.INITIAL,
  errorMessage: null,
};

const fetchMetadataURI = async uri => {
  return axios.get(uri);
};

//Thunks
export const fetchAsyncNft = createAsyncThunk(
  "nfts/fetchAsyncNft",
  async ({ address, tokens }, { rejectWithValue }) => {
    try {
      const promises = tokens.map(item => fetchMetadataURI(item.tokenURI));
      const tokenMetadata = await Promise.all(promises);
      const completeTokens = tokens.map(token => {
        const metadata = tokenMetadata.find(obj => {
          return obj.request.responseURL === token.tokenURI;
        }).data;
        return {
          ...token,
          metadata,
        };
      });
      return {
        tokens: completeTokens,
        address,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const NftMyTokenSlice = createSlice({
  name: "nftMyToken",
  initialState,
  reducers: {
    resetNftMyToken: state => {
      state.tokens = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncNft.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncNft.fulfilled, (state, { payload }) => {
        const tokens = payload.tokens.map(item => {
          return {
            tokenID: item.tokenID,
            owner: item.owner,
            tokenURI: item.tokenURI,
            metadata: item.metadata,
          };
        });
        state.tokens = {
          ...state.tokens,
          [payload.address]: tokens,
        };
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncNft.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetNftMyToken } = NftMyTokenSlice.actions;

export default NftMyTokenSlice.reducer;
