import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RateAPI from "../../../../../apiConfig/RateAPI";
import _ from 'lodash'

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  rates: null,
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const fetchAsyncPartnerRates = createAsyncThunk(
  "rates/fetchAsyncPartnerRates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await RateAPI.get(`/api/swap-pairs`);
      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  }
);

//Slices
const RatesSlice = createSlice({
  name: "rates",
  initialState,
  reducers: {
    resetRates: (state, action) => {
      state.rates = null;
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncPartnerRates.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncPartnerRates.fulfilled, (state, { payload }) => {
        if (payload.data === undefined) {
          state.status = status.ERROR;
          return;
        }

        if (_.isEmpty(payload.data.data)) {
          state.status = status.ERROR;
          return;
        }

        state.rates = payload.data.data[0];
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncPartnerRates.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});
export const { resetRates } = RatesSlice.actions;

export default RatesSlice.reducer;
