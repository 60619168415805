const phoneNumberFormatter = number => {
  if (number) {
    const cleanedNumber = number.toString().replace(/[^0-9.]/g, "");
    return (
      cleanedNumber.slice(0, 3) +
      "-" +
      cleanedNumber.slice(3, 6) +
      "-" +
      cleanedNumber.slice(6)
    );
  }
  return;
};

export default phoneNumberFormatter;

export const phoneNumberFormatter2 = phoneString => {
  if (phoneString.length >= 7) {
    return (
      phoneString.slice(0, 3) +
      "-" +
      phoneString.slice(3, 6) +
      "-" +
      phoneString.slice(6, 12)
    );
  }
  if (phoneString.length >= 4) {
    return phoneString.slice(0, 3) + "-" + phoneString.slice(3, 6);
  }
  return phoneString;
};
