import { mixpanel } from "./mixpanelConfig";
import jshashes from "jshashes";

export const trackVisitNft = () => {
  mixpanel.track("Visit MyNFTs");
};

export const trackReVisitNft = () => {
  mixpanel.track("Revisit MyNFTs");
};

export const trackSuccessAddEthNftWallet = () => {
  mixpanel.track("Success Add ETH NFT wallet");
};

export const trackUnsuccessAddEthNftWallet = () => {
  mixpanel.track("Unsuccess Add ETH NFT wallet");
};

export const trackNftView = (nftBalance, campaignNftName) => {
  mixpanel.people.set({
    "ABC NFT Balance": nftBalance,
  });
  mixpanel.track("ABC NFT View", {
    "Campaign NFT Name": campaignNftName,
  });
};

export const trackSendNftComplete = (tokenId, abcProfileId, transectionId) => {
  const abcProfileIdHash = new jshashes.SHA256().hex(abcProfileId);
  mixpanel.people.increment("# of NFT P2P", 1);
  mixpanel.people.set_once({
    "First NFT P2P Date": new Date().toISOString(),
  });
  mixpanel.people.set({
    "Last NFT P2P Date": new Date().toISOString(),
  });
  mixpanel.track("ABC NFT P2P Success", {
    "Send Token ID": tokenId,
    "Send To": abcProfileIdHash,
    "P2P Txn ID": transectionId,
  });
};

export const trackFindPeerNFTUnsuccess = () => {
  mixpanel.people.increment("# of unsuccess find peer NFT P2P", 1);
  mixpanel.track("ABC NFT P2P Unsuccess");
};

export const trackSendNftUnsuccess = () => {
  mixpanel.people.increment("# of unsuccess transfer NFT P2P", 1);
  mixpanel.track("ABC NFT P2P Unsuccess");
};

export const trackVisitReviewSendNft = () => {
  mixpanel.track("ABC NFT Load P2P review page");
};

export const trackReviewSendNftCancel = () => {
  mixpanel.track("ABC NFT Close P2P review page");
};
