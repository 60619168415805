import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as EmptySearchIcon } from "../../../../../assets/icons/empty-search.svg";
import useTranslation from "../../../../../hooks/useTranslation";

const EmptyCollection = () => {
  const { t } = useTranslation("translation", { keyPrefix: "nft" });
  return (
    <Box
      sx={{
        minHeight: "216px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.paper",
        flexDirection: "column",
        px: "20px",
      }}
    >
      <EmptySearchIcon />
      <Typography
        variant="h6"
        sx={{ mb: 1, mt: "24px", color: "grey.accent1" }}
        align="center"
      >
        {t("No Collections")}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 1, color: "grey.accent2" }}
        align="center"
      >
        {t("Your collection is empty")}
      </Typography>
    </Box>
  );
};

export default EmptyCollection;
