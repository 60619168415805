import { useEffect } from "react";
import { useDispatch, useSelector } from "../../../redux/store/configureStore";
import {
  nextPage,
  fetchAsyncVouchers,
  resetVouchers,
} from "../redux/slices/vouchers/vouchers";
import { status as vouchersStatus } from "../redux/slices/vouchers/vouchers";

const useVouchers = (id) => {
  const dispatch = useDispatch();
  const { skip, vouchers, hasMore, status } = useSelector(
    (state) => state.vouchers
  );
  const limit = 10;
  useEffect(
    () => {
      if (status !== vouchersStatus.LOADING) {
        dispatch(fetchAsyncVouchers({ skip, limit, id }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skip]
  );

  useEffect(
    () => {
      return () => {
        dispatch(resetVouchers());
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNextPage = () => dispatch(nextPage(limit));
  return { vouchers, status, hasMore, handleNextPage };
};

export default useVouchers;
