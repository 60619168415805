import React from "react";
import { Box, Modal, Typography, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";

const NftDetailsModal = ({ isShown, onClose, nft }) => {
  return (
    <Modal
      open={isShown}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          p: "22px 16px",
          width: "calc(100vw - 20px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "22px",
          }}
        >
          <IconButton
            TouchRippleProps={{ sx: { color: "primary.main" } }}
            onClick={onClose}
          >
            <CloseIcon height={12} width={12} />
          </IconButton>
        </Box>
        <Box
          alt="Cover Image"
          component="img"
          src={nft?.nftUrl}
          sx={{
            objectFit: "contain",
            aspectRatio: "1/1",
            borderRadius: "16px",
            mb: "16px",
          }}
          id="coverImage"
        />
        <Typography variant="subtitle" sx={{ color: "grey.accent1" }}>
          {nft?.nftName}
        </Typography>
        <Typography
          variant="subtitle"
          sx={{ color: "grey.accent1", my: "8px", fontWeight: 400 }}
        >
          {nft?.collectionName}
        </Typography>
        <Typography
          variant="subtitle"
          sx={{ color: "grey.accent1", fontWeight: 400 }}
        >
          {nft?.nftDescription}
        </Typography>
      </Box>
    </Modal>
  );
};

export default NftDetailsModal;
