import React from "react";

import { Box, Avatar, Skeleton } from "@mui/material";

const CardSkeleton = (props) => {
  return (
    <Box
      sx={{
        borderRadius: "16px",
        boxShadow: 5,
        bgcolor: "background.paper"
      }}
    >
      <Box
        sx={{
          m: "8px",
          pt: "8px",
          pb: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: "24px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              mr: "4px",
            }}
          >
            <Avatar
              sx={{
                height: "24px",
                width: "24px",
              }}
            >
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Skeleton
              animation="wave"
              width="90%"
              style={{ marginBottom: 6 }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            objectFit: "cover",
            width: "100%",
            mt: "4px",
            mb: "4px",
          }}
        >
          <Skeleton
            sx={{ height: 190 }}
            animation="wave"
            variant="rectangular"
          />
        </Box>
        <Box
          sx={{
            mb: "4px",
          }}
        >
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </Box>
        <Box
          sx={{
            height: "24px",
            display: "flex",
          }}
        >
          <Avatar
            sx={{
              height: "24px",
              width: "24px",
              mr: "4px",
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </Avatar>
          <Skeleton animation="wave" height={10} width="30%" />
        </Box>
      </Box>
    </Box>
  );
};

export default CardSkeleton;
