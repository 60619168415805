import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "../../../../assets/icons/arrow-down.svg";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import Prompt from "../../../../components/Prompt/Prompt";
import LoadingPrompt from "../../../../components/loadingPrompt/LoadingPrompt";
import {
  fetchAsyncNftMyCollectionSend,
  status as NftMyCollectionSendStatus,
  resetNftMyCollectionSend,
} from "../../redux/slices/nftMyCollectionSend/NftMyCollectionSend";
import {
  trackVisitReviewSendNft,
  trackSendNftComplete,
  trackSendNftUnsuccess,
} from "../../../../mixpanel/nft";
import phoneNumberFormatter from "../../../../utils/phoneNumberFormatter";
import { fullnameFormatterWithLang } from "../../../../utils/nameFormatter";
import WalletIcon from "../../../../assets/icons/wallet.svg";
import GenericErrorImg from "../../../../assets/images/GenericErrorImg.svg";
import useAuth from "../../../../hooks/useAuth";

const ReviewSendNft = () => {
  const { token, collection, destinationProfile } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  const { user } = useAuth();
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();
  const dispatch = useDispatch();
  const { transaction, status: nftMyCollectionSendStatus } = useSelector(
    state => state.nftMyCollectionSend,
  );

  const [promptErrorOpen, setPromptErrorOpen] = useState(false);
  const [promptError, setPromptError] = useState({
    title: null,
    subtitle: null,
    buttonText: null,
    onClose: null,
  });

  const [promptLoadingOpen, setPromptLoadingOpen] = useState(false);
  const [promptLoading, setPromptLoading] = useState({
    title: null,
  });

  const getUserFullname = useCallback(() => {
    return fullnameFormatterWithLang({
      firstNameEN: user?.firstNameEN,
      middleNameEN: user?.middleNameEN,
      lastNameEN: user?.lastNameEN,
      firstNameTH: user?.firstNameTH,
      middleNameTH: user?.middleNameTH,
      lastNameTH: user?.lastNameTH,
    });
  }, [user]);

  const getDestinationProfileFullname = useCallback(() => {
    return fullnameFormatterWithLang({
      firstNameEN: destinationProfile?.firstNameEN,
      middleNameEN: destinationProfile?.middleNameEN,
      lastNameEN: destinationProfile?.lastNameEN,
      firstNameTH: destinationProfile?.firstNameTH,
      middleNameTH: destinationProfile?.middleNameTH,
      lastNameTH: destinationProfile?.lastNameTH,
    });
  }, [destinationProfile]);

  useEffect(() => {
    trackVisitReviewSendNft();
  }, []);

  useEffect(() => {
    setActiveMenu(false);
  }, [setActiveMenu]);

  useEffect(() => {
    return () => {
      dispatch(resetNftMyCollectionSend());
    };
  }, [dispatch]);

  useEffect(() => {
    if (nftMyCollectionSendStatus === NftMyCollectionSendStatus.LOADING) {
      setPromptLoading({
        title: "Transaction processing in 5 second",
      });
      setPromptLoadingOpen(true);
    } else {
      setPromptLoadingOpen(false);
    }
  }, [nftMyCollectionSendStatus, navigate]);

  useEffect(() => {
    if (nftMyCollectionSendStatus === NftMyCollectionSendStatus.ERROR) {
      trackSendNftUnsuccess();
      setPromptError({
        title: "Oops! there was an issue",
        subtitle: "Please try again",
        buttonText: "Back to NFT",
        onClose: () => navigate("/wallet/nfts"),
      });
      setPromptErrorOpen(true);
    }
  }, [nftMyCollectionSendStatus, navigate]);

  useEffect(() => {
    if (
      nftMyCollectionSendStatus === NftMyCollectionSendStatus.LOADED &&
      transaction
    ) {
      trackSendNftComplete(
        token?.tokenID,
        destinationProfile?.id,
        transaction?.transactionHash,
      );
      navigate("/wallet/nfts/my-collection/complete-send-nft", {
        state: {
          token,
          tokenName: token?.metadata?.name,
          dateTime: transaction?.header?.timeReceived,
          transactionId: transaction?.transactionHash,
          fromName: getUserFullname(),
          fromMobileNumber: phoneNumberFormatter(user?.mobileNumber),
          toName: getDestinationProfileFullname(),
          toMobileNumber: phoneNumberFormatter(
            destinationProfile?.mobileNumber,
          ),
        },
      });
    }
  }, [
    navigate,
    nftMyCollectionSendStatus,
    transaction,
    token,
    destinationProfile,
    user,
    getUserFullname,
    getDestinationProfileFullname,
  ]);

  const handleClick = () => {
    dispatch(
      fetchAsyncNftMyCollectionSend({
        address: collection?.address,
        tokenID: token?.tokenID,
        mobileNumber: destinationProfile?.mobileNumber,
      }),
    );
  };

  const ConvertDetail = ({ title, details, avatar, avatarSx = {} }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "90px",
        }}
      >
        <Box
          sx={{
            mb: "16px",
          }}
        >
          <Typography variant="subtitle">{title}</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                border: "1px solid",
                borderColor: "grey.accent3",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                alt="avatar image"
                component="img"
                src={avatar}
                sx={{
                  width: "30px",
                  height: "30px",
                  ...avatarSx,
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = GenericErrorImg;
                }}
              />
            </Box>
            <Box sx={{ ml: "8px" }}>
              {details?.tokenName && (
                <Typography variant="body1" sx={{ mb: "8px" }}>
                  {details?.tokenName}
                </Typography>
              )}
              <Typography variant="body1" sx={{ mb: "8px" }}>
                {details?.name}
              </Typography>
              <Typography variant="body1" sx={{ mb: "8px" }}>
                {details?.detail}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const ConvertSeperation = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: "24px",
          mb: "24px",
        }}
      >
        <Box
          alt="Coins"
          component="img"
          src={ArrowDownwardIcon}
          sx={{
            objectFit: "cover",
            height: "16px",
            width: "12px",
            mr: "16px",
          }}
        />
        <Divider
          light
          sx={{
            flex: 1,
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Review")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px",
          backgroundColor: "background.paper",
          height: "calc(100vh - 50px)",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              mb: "16px",
              p: "16px",
            }}
          >
            <ConvertDetail
              title={t("You send")}
              avatar={token.metadata?.image}
              avatarSx={{
                borderRadius: "50%",
              }}
              details={{
                tokenName: token.metadata?.name,
                name: getUserFullname(),
                detail: phoneNumberFormatter(user?.mobileNumber),
              }}
            />
            <ConvertSeperation />
            <ConvertDetail
              title={t("To")}
              avatar={WalletIcon}
              avatarSx={{
                width: "16px",
                height: "16px",
              }}
              details={{
                name: getDestinationProfileFullname(),
                detail: phoneNumberFormatter(destinationProfile?.mobileNumber),
              }}
            />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2" sx={{ color: "grey.accent2" }}>
              {t("Transaction may take a few second.")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button
            data-testid="review"
            variant="contained"
            fullWidth
            sx={{ height: 48, width: "100%", borderRadius: "24px" }}
            disabled={
              nftMyCollectionSendStatus === NftMyCollectionSendStatus.LOADING
            }
            onClick={handleClick}
          >
            <Typography variant="button" color="background.paper">
              {t("SEND")}
            </Typography>
          </Button>
        </Box>
      </Box>
      <LoadingPrompt
        isVisible={promptLoadingOpen}
        title={promptLoading.title}
        keyPrefix={"nftMyCollection"}
      />
      <Prompt
        isVisible={promptErrorOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        buttonText={promptError.buttonText}
        keyPrefix={"nftMyCollection"}
      />
    </>
  );
};

export default ReviewSendNft;
