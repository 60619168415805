import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {uniqBy} from "lodash";
import MerchantsAPI from "../../../../../apiConfig/MerchantsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  page: 1,
  merchants: [],
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks
export const fetchAsyncMerchants = createAsyncThunk(
  "merchants/fetchAsyncMerchants",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await MerchantsAPI.get(
        `/merchants?page=${page}&limit=${limit}`
      );
      return {
        status: response.status,
        data: response.data.entries,
        totalPages: response.data.totalPages,
        limit: limit,
      };
    } catch (err) {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  }
);

//Slices
const MerchantsSlice = createSlice({
  name: "merchants",
  initialState,
  reducers: {
    nextPage: (state, action) => {
      state.page = state.page + 1;
    },
    resetMerchants: (state, action) => {
      state.page = 1;
      state.merchants = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncMerchants.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncMerchants.fulfilled, (state, { payload }) => {
        if (!payload.data || payload.data.length === 0) {
          state.errorMessage = null;
          state.hasMore = false;
          state.status = status.EMPTY;
          return;
        }

        state.hasMore = state.page < payload.totalPages;
        state.merchants = uniqBy([...state.merchants, ...payload.data], 'id');
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncMerchants.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { nextPage, resetMerchants } = MerchantsSlice.actions;

export default MerchantsSlice.reducer;
