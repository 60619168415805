import { useEffect } from "react";
import { useDispatch, useSelector } from "../../../redux/store/configureStore";
import {
  nextPage,
  fetchAsyncTransactionHistory,
  resetTransactionHistory,
} from "../redux/slices/transactionHistory/TransactionHistory";
import { status as transactionHistoryStatus } from "../redux/slices/transactionHistory/TransactionHistory";

const useTransactionHistory = (id) => {
  const dispatch = useDispatch();
  const { offset, transactionHistory, hasMore, status } = useSelector(
    (state) => state.transactionHistory
  );
  const limit = 10;
  useEffect(
    () => {
      if (status !== transactionHistoryStatus.LOADING && status !== transactionHistoryStatus.LOADED) {
        dispatch(fetchAsyncTransactionHistory({ offset, limit, id }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offset]
  );

  useEffect(
    () => {
      return () => {
        dispatch(resetTransactionHistory());
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleNextPage = () => dispatch(nextPage(limit));

  return { transactionHistory, status, hasMore, handleNextPage };
};

export default useTransactionHistory;
