import * as React from "react";
import CardSkeleton from "./CardSkeleton";
import Grid from "@mui/material/Grid";

const DealsSkeleton = () => {
  var length = [];
  for (var i = 0; i < 10; i++) {
    length.push(i);
  }

  return (
    <Grid container spacing={2}>
      {length.map((voucher) => {
        return (
          <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={voucher}>
            <CardSkeleton />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DealsSkeleton;
