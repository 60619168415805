import React from "react";
import { List, Box, Typography } from "@mui/material";
import useTranslation from "../../../../hooks/useTranslation";
import InfiniteScroll from "react-infinite-scroll-component";
import TransactionHistoryCard from "./transactionHistoryCard/TransactionHistoryCard";
import TransactionHistoryGroup from "./transactionHistoryGroup/TransactionHistoryGroup";
import TransactionEmpty from "./transactionEmpty/TransactionEmpty";
import TransactionError from "./transactionError/TransactionError";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import { status as transactionHistoryStatus } from "../../redux/slices/transactionHistory/TransactionHistory";
import useTransactionHistory from "../../hooks/useTransactionHistory";
import ProgressLoad from "../../../../components/progressLoad/ProgressLoad";

const TransactionHistoryList = ({ id, coinAccount }) => {
  const { t } = useTranslation("translation", { keyPrefix: "walletHistory" });
  const { transactionHistory, status, hasMore, handleNextPage } =
    useTransactionHistory(id);

  const renderTransactionList = (transactions) => {
    return transactions.map((item, index) => (
      <TransactionHistoryCard 
        key={index} 
        data={item} 
        coinAccount={coinAccount} />
    ));
  };
  const renderGroupList = () => {
    let tempGroup = {};
    transactionHistory.forEach((transaction) => {
      const formattedDate = formatDateTime(
        transaction.createdAt,
        "DD MMM YYYY"
      );
      if (tempGroup[formattedDate]) {
        tempGroup[formattedDate].push(transaction);
      } else {
        tempGroup[formattedDate] = [transaction];
      }
    });
    return Object.keys(tempGroup).map((formattedDate) => (
      <TransactionHistoryGroup key={formattedDate} title={formattedDate}>
        {renderTransactionList(tempGroup[formattedDate])}
      </TransactionHistoryGroup>
    ));
  };

  return (
    <Box
      sx={{
        pb: "80px",
      }}
    >
      <Box
        sx={{
          m: "20px 0px",
          padding: "16px",
        }}
      >
        <Typography variant="h6" sx={{ color: "grey.accent1" }}>
          {t("Activities")}
        </Typography>
      </Box>
      {
        status === transactionHistoryStatus.LOADING 
        ? 
          (
            <Box sx={{ mb: "60px" }}>
              <ProgressLoad />
            </Box>
          ) 
        : status === transactionHistoryStatus.ERROR 
        ? <TransactionError />
        : status === transactionHistoryStatus.EMPTY 
        ? <TransactionEmpty />
        : status === transactionHistoryStatus.LOADED 
        ? (
            <InfiniteScroll
              id="merchant-scroll"
              dataLength={transactionHistory.length}
              next={handleNextPage}
              hasMore={false}
              // hasMore={hasMore}
              loader={<ProgressLoad />}
            >
              <List>{renderGroupList()}</List>
            </InfiniteScroll>
          )
        : null
      }
    </Box>
  );
};

export default TransactionHistoryList;
