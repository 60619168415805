import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import EmptyCollection from "./emptyCollection/EmptyCollection";
import Nftlist from "./nftList/NftList";
import NftWalletConnectionArea from "./nftWalletConnectionArea/NftWalletConnectionArea";
import useNfts from "../../hooks/useNfts";
import { status as NftsStatus } from "../../redux/slices/nfts/Nfts";
import NftErrorModal from "./nftErrorModal/NftErrorModal";
import NftLoadingOverlay from "./nftLoadingOverlay/NftLoadingOverlay";
import NftDetailsModal from "./nftDetailsModal/NftDetailsModal";
import {
  trackSuccessAddEthNftWallet,
  trackUnsuccessAddEthNftWallet,
} from "../../../../mixpanel/nft";

const MyNft = () => {
  const {
    nfts,
    ownerAddress,
    status,
    hasMore,
    connect,
    removeOwnerAddress,
    resetError,
    handleNextPage,
  } = useNfts();
  const [selectedNft, setSelectedNft] = useState(null);

  const closeDetailsModal = () => setSelectedNft(null);

  useEffect(() => {
    if (status === NftsStatus.LOADED) {
      trackSuccessAddEthNftWallet();
    } else if (status === NftsStatus.ERROR) {
      trackUnsuccessAddEthNftWallet();
    }
  }, [status]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {status === NftsStatus.EMPTY || status === NftsStatus.INITIAL ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyCollection />
          </Box>
        ) : (
          <Nftlist selectNft={setSelectedNft} nfts={nfts} />
        )}

        <NftWalletConnectionArea
          connect={connect}
          nextPage={handleNextPage}
          removeOwnerAddress={removeOwnerAddress}
          isConnected={!!ownerAddress}
          hasMore={hasMore}
        />
      </Box>
      <NftErrorModal
        isShown={status === NftsStatus.ERROR}
        onClose={resetError}
      />
      <NftLoadingOverlay isShown={status === NftsStatus.LOADING} />
      <NftDetailsModal
        isShown={!!selectedNft}
        onClose={closeDetailsModal}
        nft={selectedNft}
      />
    </>
  );
};

export default MyNft;
