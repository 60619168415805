import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import consentAPI from "../../../apiConfig/consentAPI";
import { CollectionPoint } from "../../../constants/consent";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

const initialState = {
  saveConsents: null,
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const fetchAsyncSaveConsent = createAsyncThunk(
  "saveConsent/fetchAsyncSaveConsent",
  async ({ consentList }, { rejectWithValue }) => {
    try {
      const response = await consentAPI.post("/save-consent", {
        consentList,
        collectionPoint: CollectionPoint.OPEN_APP,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const SaveConsentSlice = createSlice({
  name: "saveConsent",
  initialState,
  reducers: {
    resetSaveConsent: state => {
      state.saveConsent = null;
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncSaveConsent.pending, state => {
        state.status = status.LOADING;
        state.errorMessage = null;
      })
      .addCase(fetchAsyncSaveConsent.fulfilled, (state, { payload }) => {
        state.saveConsents = payload;
        state.status = status.LOADED;
        state.errorMessage = null;
      })
      .addCase(fetchAsyncSaveConsent.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetSaveConsent } = SaveConsentSlice.actions;

export default SaveConsentSlice.reducer;
