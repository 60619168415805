import axios from "axios";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const ProfileAPI = axios.create({
  baseURL: apiEndpoint.profile,
  headers: {
    "content-type": "application/json",
  },
});

ProfileAPI.interceptors.request.use(refreshInterceptor)

export default ProfileAPI;
