import { mixpanel } from "./mixpanelConfig";
import { CollectionPoint } from "../constants/consent";

export const trackSkipPDPAConsent = collectionPoint => {
  mixpanel.track("Skip PDPA Consent", {
    "Collection Point": collectionPoint,
  });
};

export const trackSavePDPAConsent = (
  collectionPoint,
  listActiveConsent = [],
) => {
  mixpanel.people.increment("# of Save PDPA Consent", 1);
  mixpanel.people.set_once({
    "First Save PDPA Consent Date": new Date().toISOString(),
  });
  mixpanel.people.set({
    "Last Save PDPA Consent Date": new Date().toISOString(),
  });
  if (collectionPoint === CollectionPoint.OPEN_APP) {
    listActiveConsent.forEach(item => {
      mixpanel.people.union("Active Consents", item);
    });
  } else {
    mixpanel.people.set({
      "Active Consents": listActiveConsent,
    });
  }
  mixpanel.track("Save PDPA Consent", {
    "Active Consents": listActiveConsent,
    "Collection Point": collectionPoint,
  });
};
