import axios from "axios";
// import MockAdapter from "axios-mock-adapter";
// import mockApis from "../__fakeAPI__/customerApi";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const CoinsAPI = axios.create({
  baseURL: apiEndpoint.coins,
  headers: {
    "content-type": "application/json",
  },
});

CoinsAPI.interceptors.request.use(refreshInterceptor);

// mockApis(new MockAdapter(CoinsAPI));

export default CoinsAPI;
