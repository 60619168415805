import {
  Typography,
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
  Avatar,
  Skeleton,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Coins from "../../../../assets/icons/coins.svg";
import useTranslation from "../../../../hooks/useTranslation";
import { coinFormatter } from "../../../../utils/coinFormatter";
import SwapIcon from "../../../../assets/icons/swap.svg";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncCoins,
  resetCoins,
  status as CoinsStatus,
} from "../../redux/slices/coins/Coins";
import {
  fetchAsyncPartnerRates,
  resetRates,
  status as PartnerRatesStatus,
} from "../../redux/slices/partnerRates/PartnerRates";
import {
  fetchAsyncTruePointsBalance,
  resetTruePointsBalance,
  status as TruePointsBalanceStatus,
} from "../../redux/slices/truePointsBalance/TruePointsBalance";
import useNavigationHook from "../../../../hooks/useNavigation";
import TruePointLogo from '../../../../assets/images/MyWallet/truepoint 1.png'
import toast, { Toaster, ToastBar } from "react-hot-toast";
import queryString from 'query-string'
import AuthContextService from "../../../../contexts/authContext/AuthContextServices";
import { fetchTrueIdLogin, status as trueIdLoginStatus } from "../../redux/slices/trueIdLogin/trueIdLogin";
import { useEffect } from "react";

const ConvertCoins = (props) => {
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { coins, status } = useSelector((state) => state.coins);
  const rates = useSelector((state) => state.rates);
  const truePointsBalance = useSelector((state) => state.truePointsBalance);
  const trueIdLogin = useSelector((state) => state.trueIdLogin)
  const location = useLocation()

  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });
  const [values, setValues] = React.useState({
    balance: "",
  });
  const [error, setError] = React.useState({
    hasError: false,
    message: "",
    disableButton: false,
  });
  
  useEffect(() => {
    if(trueIdLogin.status === trueIdLoginStatus.LOADED){
        toast.remove()
        toast(t("Binding Success!"), {
          duration: 3000,
          style: {
            textAlign: "start",
            padding: "12px, 16px, 12px, 16px",
            color: "#FFFFFF",
            backgroundColor: "#141312",
            width: "100%",
            borderRadius: "16px",
          },
        })
        if (status !== CoinsStatus.LOADING) {
          dispatch(fetchAsyncCoins());
        }
        if (rates.status !== PartnerRatesStatus.LOADING) {
          dispatch(fetchAsyncPartnerRates());
        }
        if (truePointsBalance.status !== TruePointsBalanceStatus.LOADING) {
          dispatch(fetchAsyncTruePointsBalance());
        }
        // this line will remove location.search aka. querystring from browser without reload the page
        window.history.replaceState({}, document.title)
        return
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trueIdLogin.status])

  React.useEffect(() => {
    setActiveMenu(false);
    const query = queryString.parse(location.search)
    const trueRandomState = AuthContextService.getTrueRandomStateFromLocalStorage()
    if (query && query.code && query.state && query.state === trueRandomState) {
      if (trueIdLogin.status !== trueIdLoginStatus.LOADING) {
        dispatch(fetchTrueIdLogin({code: query.code}))
      }
    } else {
      if (status !== CoinsStatus.LOADING) {
        dispatch(fetchAsyncCoins());
      }
      if (rates.status !== PartnerRatesStatus.LOADING) {
        dispatch(fetchAsyncPartnerRates());
      }
      if (truePointsBalance.status !== TruePointsBalanceStatus.LOADING) {
        dispatch(fetchAsyncTruePointsBalance());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      // dispatch(resetPartner());
      dispatch(resetCoins());
      dispatch(resetRates());
      dispatch(resetTruePointsBalance());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      truePointsBalance.status === TruePointsBalanceStatus.LOADED &&
      rates.status === PartnerRatesStatus.LOADED
    ) {
      if (truePointsBalance.balance < parseInt(values.balance)) {
        setError({
          hasError: true,
          message: t(
            "Your point is insufficient"
          ),
        });
      } else if (
        values.balance &&
        values.balance / rates.rates.rate <
          rates.rates.minimumAbcCoinsToBeSwapped
      ) {
        setError({
          hasError: true,
          message: t("Please convert ABC Coin not less than minimum defined"),
        });
      } else
        setError({
          hasError: false,
          message: "",
        });
    } else {
      setError({
        disableButton: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.balance]);

  const handleClick = () => {
    navigate(`/review`, {
      state: {
        partner: {logo: TruePointLogo, name: rates.rates.from},
        rates,
        convertPoints: values.balance.toString(),
        convertedCoins:  (values.balance / rates.rates.rate).toString()
      }
    });
  };

  const handleChange = (prop) => (event) => {
    var regEx = new RegExp("^[0-9]*$");
    if (!values.balance && event.target.value === "0")
      console.log("Cannot start with 0");
    else if (truePointsBalance.balance < parseInt(event.target.value)) {
      if (!error.hasError) {
        setValues({ ...values, [prop]: event.target.value });
      }
      setError({
        hasError: true,
        message: t(
          "Please input the amount not greater than available balance"
        ),
      });
    } else if (regEx.test(event.target.value))
      setValues({ ...values, [prop]: event.target.value });
    else
      setError({
        hasError: true,
        message: t("Only number value is allowed"),
      });
  };

  const ABCConvertCard = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          backgroundColor: "background.paper",
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          mt: "24px",
          p: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "90px",
          }}
        >
          <Box>
            <Typography variant="body1">{"ABC"}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Avatar
              id='img_abcLogo_ConvertLoyaltyPointPage'
              sx={{
                height: "40px",
                width: "40px",
                mt: "15px",
              }}
              src={Coins}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            m: "36px 0 0 15px",
            overflow: "scroll",
          }}
        >
          <Typography 
            id='lbl_amountABC_ConvertLoyaltyPointPage' 
            variant="h4" sx={{ mb: "4px" }}>
            {rates.status === PartnerRatesStatus.LOADED
              ? (values.balance / rates.rates.rate).toLocaleString()
              : "0"}
          </Typography>
          <Typography
            id='lbl_ABCBalance_ConvertLoyaltyPointPage'
            variant="helpingText"
            color={"grey.accent2"}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {
              status === CoinsStatus.LOADING 
              ? (
                <Skeleton
                  animation="wave"
                  height={"20px"}
                  width="100px"
                  id="coins-loading"
                />
              ) 
              : status === CoinsStatus.ERROR 
              ? t("Unable to retrieve balance")
              : status === CoinsStatus.LOADED 
              ? (`${t("ABC Balance")} ${coinFormatter(coins.balance)}`) 
              : null
            }
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Helmet id='lbl_title_ConvertLoyaltyPointPage'>
        <title>{t("Convert")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px 0",
          backgroundColor: "grey.accent4",
          height: "calc(100vh - 50px)",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "background.paper",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              mb: "24px",
              p: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mr: "15px",
                minWidth: "90px",
              }}
            >
              <Typography 
                id='lbl_truePointSection_ConvertLoyaltyPointPage' 
                variant="body1">
                {rates.status === PartnerRatesStatus.LOADING ? (
                  <Skeleton
                    animation="wave"
                    height={"30px"}
                    width="100px"
                    id="partner-loading"
                  />
                ) : rates.status ===
                  PartnerRatesStatus.ERROR ? null : rates.status ===
                  PartnerRatesStatus.LOADED ? (
                  rates.rates.from
                ) : null}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Avatar
                  id='img_truePointLogo_ConvertLoyaltyPointPage'
                  sx={{
                    height: "40px",
                    width: "40px",
                    mt: "15px",
                  }}
                  src={TruePointLogo}
                />
              </Box>
            </Box>
            <FormControl
              variant="standard"
              sx={{
                display: "flex",
                flexGrow: 1,
                mt: 3,
              }}
            >
              <Input
                // id="input"
                id='txt_truePoint_ConvertLoyaltyPointPage'
                error={error.hasError ? true : false}
                value={values.balance}
                inputProps={{
                  inputMode: "numeric",
                  style: { 
                    color: error.hasError ? "#E50914" : "#141312",
                    fontWeight: 600
                  },
                }}
                color={!error.hasError && "grey"}
                placeholder={"0"}
                sx={{ fontSize: "32px" }}
                onChange={handleChange("balance")}
                endAdornment={
                  <InputAdornment position="end">
                    <Typography 
                      id='lbl_maxTruePointBalance_ConvertLoyaltyPointPage' 
                      variant="subtitle" 
                      color={"primary"}>
                      MAX
                    </Typography>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography
                id='lbl_truePointBalance_ConvertLoyaltyPointPage'
                  color={error.hasError ? "error" : "grey.accent2"}
                  variant="helpingText"
                >
                  {
                    truePointsBalance.status === TruePointsBalanceStatus.LOADING 
                    ? (
                      <Skeleton
                        animation="wave"
                        height={"20px"}
                        width="100px"
                        id="partner-loading"
                      />
                      ) 
                    : truePointsBalance.status === TruePointsBalanceStatus.ERROR
                    ? (`${truePointsBalance.status}: ${truePointsBalance.errorMessage}`) // t("Unable to retrieve balance")
                    : truePointsBalance.status === TruePointsBalanceStatus.LOADED 
                    ? (
                        error.hasError 
                        ? (error.message) 
                        : (`${t("Partner Balance")} ${coinFormatter(truePointsBalance.balance?.toString())} ${t("points")}`)
                      ) 
                    : null
                  }
                </Typography>
              </FormHelperText>
            </FormControl>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box
              alt="Coins"
              component="img"
              src={SwapIcon}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "16px",
              }}
            />
            <Typography id='lbl_coversionRate_ConvertLoyaltyPointPage' color={"grey.accent2"}>
              {
                rates.status === PartnerRatesStatus.LOADING 
                ? (
                  <Skeleton
                    animation="wave"
                    height={"30px"}
                    width="100px"
                    id="rates-loading"
                  />
                ) 
                : rates.status === PartnerRatesStatus.ERROR 
                ? t('Unable to retrieve conversion rate') 
                : rates.status === PartnerRatesStatus.LOADED 
                ? (
                  `${t("Conversion rate")}: ${rates.rates.rate} ${
                    rates.rates.from
                  } = 1 ${rates.rates.to}`
                  ) 
                : null
              }
            </Typography>
          </Box>
          <ABCConvertCard id="abc-card" />
        </Box>
        <Box sx={{ p: "32px 0" }}>
          <Button
            id="review"
            variant="contained"
            fullWidth
            sx={{ height: 48, width: "100%", borderRadius: "24px" }}
            disabled={
              error.hasError || !values.balance || error.disableButton
                ? true
                : false
            }
            onClick={handleClick}
          >
            <Typography variant="button" color="background.paper">
              {t("Review")}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      >
        {(t) => (
            // Custimize default toast
            <ToastBar toast={t}>
              {({ message }) => (
                <>
                  <div>{message}</div>
                </>
              )}
            </ToastBar>
          )}
      </Toaster>
    </Box>
  );
};
export default ConvertCoins;
