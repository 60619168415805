import React from "react";
import useTranslation from "../../../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Box, Avatar, Typography, ButtonBase } from "@mui/material";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import useNavigationHook from "../../../../hooks/useNavigation";
import { coinFormatter } from "../../../../utils/coinFormatter";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import { getTransactionDetail, CURRENCY, KIND_TYPE } from "../transactionHistoryList/transactionHistoryCard/TransactionHistoryCard";

const TransactionDetails = () => {
  const { 
    transactionHash,
    from,
    to,
    amount,
    kind,
    metadata,
    confirmedAt,
    currency
  } = useLocation().state;

  const setActiveMenu = useNavigationHook();
  const { t } = useTranslation("translation", {
    keyPrefix: "transactionDetails",
  });
  const lang = localStorage.getItem("lang")

  const {title, symbol, icon} = getTransactionDetail(t, lang, kind, CURRENCY)
  const toaster = () => {
    toast.remove();
    toast(t("Transaction ID Copied"), {
      style: {
        textAlign: "start",
        padding: "12px, 16px, 12px, 16px",
        color: "#FFFFFF",
        backgroundColor: "#141312",
        width: "100%",
        borderRadius: "16px",
      },
    });
  };

  const labels = [
    {
      label: t("Date"),
      data: formatDateTime(confirmedAt, "DD MMM YYYY HH:mm"),
    },
    {
      label: t("Type"),
      data: (kind === KIND_TYPE.send || kind === KIND_TYPE.receive) ? t('Peer to peer transfer') : title,
    },
    {
      label: t("Transaction ID"),
      data: transactionHash
        ? `${transactionHash.slice(0, 7)}...${transactionHash.slice(transactionHash.length - 7, transactionHash.length)}`
        : transactionHash,
    },
    {
      label: t("From"),
      data: from
        ? `${from.slice(0, 7)}...${from.slice(from.length - 7, from.length)}`
        : from,
    },
    
  ];

  if (kind === KIND_TYPE.send || kind === KIND_TYPE.receive) {
    labels.push(
      {
        label: t("To"),
        data: `xxxxxx${metadata?.mobileNumber.slice(6, 10)}`
      }
    )
  } else {
    labels.push(
      {
        label: t("To"),
        data: `${to.slice(0, 7)}...${to.slice(to.length - 7, to.length)}`
      }
    )
  }

  if(kind === KIND_TYPE.swap) {
    const {exchangeRate, from: metaFrom} = metadata
    labels.push({
      label: t("You spent"),
      data: `-${amount * exchangeRate} ${metaFrom}`
    })
  }

  React.useEffect(() => {
    setActiveMenu(false);
  });

  return (
    <>
      <Helmet>
        <title>{t("Transaction Detail")}</title>
      </Helmet>
      <Box sx={{ height: 50 }} />
      <Box
        sx={{
          border: 1,
          m: "0px 20px 0px 20px",
          backgroundColor: "background.paper",
          borderRadius: "16px",
          borderColor: "grey.accent3",
          p: "16px 0px 32px 0px",
        }}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            m: "24px 0 24px 0",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Avatar
            sx={{
              border: 1,
              bgcolor: "grey.accent4",
              borderColor: "grey.accent3",
              width: "40px",
              height: "40px",
            }}
          >
            {metadata?.merchant?.logo  ? (
              <Box
                component="img"
                src={metadata.merchant.logo }
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            ) : (
              icon
            )}
          </Avatar>
          <Typography variant="subtitle" sx={{ my: "16px" }}>
            {`${symbol}${coinFormatter(amount + '')}  ${currency}`}
          </Typography>
        </Box>
        {labels.map(({ label, data }) => {
          return label !== t("Transaction ID") ? (
            <Box
              key={label}
              sx={{
                m: "32px 16px 0 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
              id="toastButton"
            >
              <Typography variant="body2" color="grey.accent2">
                {label}
              </Typography>
              <Typography 
                variant="body2"
                sx={{
                  flexGrow: 1,
                  textAlign: 'end'
                }}
                >{data}
              </Typography>
            </Box>
          ) : (
            <Box
              key={label}
              sx={{
                m: "32px 16px 0 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="btn"
              data-clipboard-text={transactionHash}
              onClick={() => toaster()}
            >
              <Typography
                variant="body2"
                color="grey.accent2"
                sx={{ pt: 0.75 }}
              >
                {label}
              </Typography>
              <ButtonBase
                sx={{
                  display: "flex",
                  p: "4px 16px 4px 12px",
                  borderRadius: "16px",
                  backgroundColor: "grey.accent4",
                }}
              >
                <Typography variant="body2">{data}</Typography>
                <CopyIcon style={{ position: "relative", left: "6px" }} />
              </ButtonBase>
            </Box>
          );
        })}
        <Toaster
          position="bottom-center"
          reverseOrder={false}
        >
          {(t) => (
            // Custimize default toast
            <ToastBar toast={t}>
              {({ message }) => (
                <>
                  <div>{message}</div>
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </Box>
    </>
  );
};

export default TransactionDetails;
