import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DealsAPI from "../../../../../apiConfig/DealsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

const initialState = {
  redeemDetails: null,
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const redeemAsync = createAsyncThunk(
  "redeem/redeemAsync",
  async ({ id, priceAbc }, { rejectWithValue }) => {
    try {
      const response = await DealsAPI.post(`/vouchers`, {
        dealID: id,
        expectedPrice: priceAbc,
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  }
);

//Slices
const RedeemSlice = createSlice({
  name: "redeem",
  initialState,
  reducers: {
    resetRedeem: (state, action) => {
      state.redeemDetails = null;
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(redeemAsync.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(redeemAsync.fulfilled, (state, { payload }) => {
        state.redeemDetails = payload.data;
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(redeemAsync.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetRedeem } = RedeemSlice.actions;

export default RedeemSlice.reducer;
