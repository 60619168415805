import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import NftMyCollectionAPI from "../../../../../apiConfig/NftMyCollectionAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  collections: [],
  status: status.INITIAL,
  errorMessage: null,
  visitNftTimes: 0,
};

const fetchContractURI = async uri => {
  return axios.get(uri);
};

//Thunks
export const fetchAsyncNftMyCollection = createAsyncThunk(
  "nfts/fetchAsyncNftMyCollection",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NftMyCollectionAPI.get("/nft/tokens");
      const promises = response.data.collections.map(item =>
        fetchContractURI(item.contractURI),
      );
      const contractMetadata = await Promise.all(promises);
      return response.data.collections.map(collection => {
        const metadata = contractMetadata.find(obj => {
          return obj.request.responseURL === collection.contractURI;
        }).data;
        return {
          ...collection,
          metadata,
        };
      });
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const NftMyCollectionSlice = createSlice({
  name: "nftMyCollection",
  initialState,
  reducers: {
    resetNftMyCollection: state => {
      state.collections = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
    increaseVisitNftTimes: state => {
      state.visitNftTimes = state.visitNftTimes + 1;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncNftMyCollection.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncNftMyCollection.fulfilled, (state, { payload }) => {
        state.collections = payload.map(item => {
          return {
            address: item.address,
            name: item.name,
            symbol: item.symbol,
            contractURI: item.contractURI,
            metadata: item.metadata,
            tokens: item.tokens,
          };
        });
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncNftMyCollection.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetNftMyCollection, increaseVisitNftTimes } =
  NftMyCollectionSlice.actions;

export default NftMyCollectionSlice.reducer;
