import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import walletCoinIcon from "../../../../assets/icons/coins.svg";
import useTranslation from "../../../../hooks/useTranslation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncCoins,
  status as CoinsStatus,
} from "../../redux/slices/coins/Coins";
import useAuth from "../../../../hooks/useAuth";
import { coinFormatter } from "../../../../utils/coinFormatter";
import MyWalletSkeleton from "../../skeletonLoading/MyWalletSkeleton";
import TransactionHistoryList from "../transactionHistoryList/TransactionHistoryList";
import phoneNumberFormatter from "../../../../utils/phoneNumberFormatter";
import ConvertButton from "./convertButton/ConvertButton";
import { featureFlag } from "../../../../config";
import SendLogo from '../../../../assets/icons/wallet/send-button.svg'
import { useNavigate } from "react-router-dom";
import { trackVisitMyWalletPage } from "../../../../mixpanel";

const MyWallet = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "myWallet",
  });
  const { coins, status } = useSelector((state) => state.coins);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendButtonClick = () => {
    navigate('/transfer')
  }

  React.useEffect(() => {
    if (status !== CoinsStatus.LOADING) {
      dispatch(fetchAsyncCoins());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (coins) {
      trackVisitMyWalletPage(coins.balance);
    }
  }, [coins]);

  React.useEffect(() => {
    // return () => dispatch(resetCoins());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MyWalletError = () => {
    return (
      <Box
        sx={{
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          minHeight: "292px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.paper",
        }}
      >
        <Typography
          sx={{
            m: "122px 47px",
          }}
          color="grey.accent2"
          variant="body2"
          align="center"
        >
          {t(
            "This function is temporarily unavailable. Please try again later"
          )}
        </Typography>
      </Box>
    );
  };

  const MyWalletComponent = () => {
    let coinsObj = coins;
    let length = coinsObj.account.length;
    let redactedAddress =
      coinsObj.account.substring(0, 10) +
      "..." +
      coinsObj.account.substring(length - 10, length);
    return (
      <Box
        sx={{
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          minHeight: "292px",
          backgroundColor: "background.paper",
        }}
      >
        <Box sx={{ m: "24px 16px" }}>
          <Box
            sx={{
              mb: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              alt="Coins"
              component="img"
              src={walletCoinIcon}
              sx={{
                height: "40px",
                width: "40px",
                mr: "8px",
              }}
            />
            <Typography variant="subtitle">
              {t("ABC coin")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              mb: "8px",
            }}
          >
            <Typography variant="h5" sx={{ mb: "4px" }}>
              {coinFormatter(coins.balance)}
            </Typography>
            <Typography variant="body2" color="grey.accent2">
              {t("Balance")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="grey.accent2"
              sx={{
                mb: "8px",
              }}
            >
              {t("ABC wallet address")}
            </Typography>
            <Box
              sx={{
                backgroundColor: "grey.accent4",
                mb: "16px",
                minHeight: "40px",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ m: "8px 16px" }}>
                {phoneNumberFormatter(user.mobileNumber)}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "grey.accent4",
                minHeight: "40px",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ m: "8px 16px" }}>
                {redactedAddress}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '24px',
            }}>
              <IconButton 
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleSendButtonClick}
              >
                <Box component='img' src={SendLogo} sx={{width: '48px'}} />
                <Typography 
                  variant="subtitle" 
                  sx={{
                    color: '#333333',
                    marginTop: '6px'
                  }}>
                  {t("Send")}
                </Typography>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ m: "0px 20px" }}>
        {status === CoinsStatus.LOADING ? (
          <MyWalletSkeleton id="wallet-loading" />
        ) : status === CoinsStatus.ERROR ? (
          <MyWalletError id="wallet-error" />
        ) : status === CoinsStatus.EMPTY ? (
          <MyWalletComponent id="wallet-empty" />
        ) : coins && status !== CoinsStatus.INITIAL ? (
          <MyWalletComponent id="wallet-component" />
        ) : null}
      </Box>
      {
        (featureFlag.enebleConvertLoyaltyPoint === 'true') && <ConvertButton id='convert-btn' />
      }
      {
        (coins && status !== CoinsStatus.INITIAL) && <TransactionHistoryList id={user.sub} coinAccount={coins.account} />
      }
    </>
  );
};

export default MyWallet;
