import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContextServices from "./AuthContextServices";
import { useSelector } from "../../redux/store/configureStore";
import jwtDecode from "jwt-decode";
import { trackLoginSignup, trackTMNSignup } from "../../mixpanel";
import * as Sentry from "@sentry/react";

export const authStatus = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOGGED_IN: "LOGGED_IN",
  INACTIVE: "INACTIVE",
  ONBOARDING: "ONBOARDING",
  TOS_REQUIRED: "TOS_REQUIRED",
  GUEST: "GUEST",
  ERROR: "ERROR",
};

const authAction = {
  INITIALIZE_EXISTING: "INITIALIZE_EXISTING",
  INITIALIZE_GUEST: "INITIALIZE_GUEST",
  ACCEPT_TOS: "ACCEPT_TOS",
  LOADING: "LOADING",
};

export const initialState = {
  tmwTokenRef: null,
  token: null,
  user: null,
  status: authStatus.INITIAL,
};

export const AuthContext = createContext({
  ...initialState,
  acceptTOS: () => Promise.resolve(),
  initialize: () => {},
});

export const reducer = (state, action) => {
  if (action.type === authAction.LOADING) {
    const { status } = action.payload;
    return {
      ...state,
      status,
    };
  }

  if (action.type === authAction.INITIALIZE_EXISTING) {
    const { tmwTokenRef, token, user, status } = action.payload;
    return {
      ...state,
      tmwTokenRef,
      token,
      user,
      status,
    };
  } else if (action.type === authAction.INITIALIZE_GUEST) {
    const { tmwTokenRef, status } = action.payload;
    return {
      ...state,
      tmwTokenRef,
      status,
    };
  } else if (action.type === authAction.ACCEPT_TOS) {
    const { token, user, status } = action.payload;
    return {
      ...state,
      token,
      user,
      status,
    };
  }

  return state;
};

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { terms } = useSelector((state) => state.terms);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.status === authStatus.INITIAL) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    let keyFromParam = AuthContextServices.getQueryParam();
    let tmwTokenRef =
      keyFromParam ||
      state.tmwTokenRef ||
      AuthContextServices.getTmwTokenRefFromLocalStorage();

    if (!tmwTokenRef) {
      dispatch({
        type: authAction.INITIALIZE_GUEST,
        payload: { status: authStatus.GUEST },
      });
      return;
    }
    try {
      const { data } = await AuthContextServices.getUserFromKey(tmwTokenRef);
      AuthContextServices.setLocalStorageToken(JSON.stringify(data));
      AuthContextServices.setLocalStorageTmwTokenRef(tmwTokenRef);

      const parsedAccessToken = jwtDecode(data.access_token);

      if (parsedAccessToken.status === "onboarding") {
        dispatch({
          type: authAction.INITIALIZE_EXISTING,
          payload: {
            tmwTokenRef: tmwTokenRef,
            token: data,
            status: authStatus.ONBOARDING,
          },
        });
        return;
      }
      if (parsedAccessToken.termsAgreement !== "agreed") {
        dispatch({
          type: authAction.INITIALIZE_EXISTING,
          payload: {
            tmwTokenRef: tmwTokenRef,
            token: data,
            status: authStatus.TOS_REQUIRED,
          },
        });
        return;
      }

      const profileResponse = await AuthContextServices.getProfile(
        parsedAccessToken.mobile
      );

      const user = {
        ...profileResponse.data.items[0],
        sub: parsedAccessToken.sub,
      };

      trackLoginSignup(user);
      trackTMNSignup(user);

      dispatch({
        type: authAction.INITIALIZE_EXISTING,
        payload: {
          tmwTokenRef: tmwTokenRef,
          token: data,
          user,
          status: authStatus.LOGGED_IN,
        },
      });
      return;
    } catch (err) {
      Sentry.captureException(err);
      if (err.response) {
        if (
          err.response.status === 401 &&
          err.response.data.error_description === "User disabled"
        ) {
          dispatch({
            type: authAction.INITIALIZE_EXISTING,
            payload: {
              status: authStatus.INACTIVE,
            },
          });
          return;
        }
      }

      dispatch({
        type: authAction.INITIALIZE_GUEST,
        payload: {
          tmwTokenRef: tmwTokenRef,
          status: authStatus.ERROR,
        },
      });
      return;
    }
  };

  const acceptTOS = async () => {
    dispatch({
      type: authAction.LOADING,
      payload: {
        status: authStatus.LOADING,
      },
    });

    try {
      let language = localStorage.getItem("lang");
      language === "en-US" && (language = "en");
      await AuthContextServices.acceptTOS(
        language,
        "abc-wallet-tnc",
        terms?.data?.version
      );
      const response = await AuthContextServices.refreshUser(
        state.token?.refresh_token
      );
      const parsedAccessToken = jwtDecode(response.data.access_token);
      const profileResponse = await AuthContextServices.getProfile(
        parsedAccessToken.mobile
      );

      const user = {
        ...profileResponse.data.items[0],
        sub: parsedAccessToken.sub,
      };
      const isSignup =
        jwtDecode(state.token.access_token).status === "onboarding";
      trackLoginSignup(user, isSignup);
      trackTMNSignup(user, isSignup);

      AuthContextServices.setLocalStorageToken(JSON.stringify(response.data));

      dispatch({
        type: authAction.ACCEPT_TOS,
        payload: {
          token: response.data,
          user,
          status: authStatus.LOGGED_IN,
        },
      });
      // navigate("/", { replace: true });
    } catch (err) {
      navigate("/unhandled-exception", { replace: true });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        acceptTOS,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
