import { applyMiddleware, createStore, compose } from "redux";
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const configureStore = () => {
  const middlewares = [thunk];
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(rootReducer(), enhancers);
};

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default configureStore;
