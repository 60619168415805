import React from "react";
import useTranslation from "../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";

const Help = () => {
  const { t } = useTranslation("translation", { keyPrefix: "menu" });
  return (
    <Helmet>
      <title>{t("Help")}</title>
    </Helmet>
  );
};

export default Help;
