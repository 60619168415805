export const fullnameFormatter = ({ firstName, middleName, lastName }) => {
  if (middleName) {
    return `${firstName || ""} ${middleName} ${lastName || ""}`;
  } else {
    return `${firstName || ""} ${lastName || ""}`;
  }
};

export const fullnameFormatterWithLang = nameObj => {
  const lang = localStorage.getItem("lang");
  let useLang;
  if (lang === "th" && nameObj.firstNameTH) {
    useLang = "TH";
  } else {
    useLang = "EN";
  }

  return fullnameFormatter({
    firstName: nameObj[`firstName${useLang}`],
    middleName: nameObj[`middleName${useLang}`],
    lastName: nameObj[`lastName${useLang}`],
  });
};
