import React, { useState } from "react";
import { Box, Button, Avatar, Typography } from "@mui/material";
import NftWalletInputField from "./nftWalletInputField/NftWalletInputField";
import useTranslation from "../../../../../hooks/useTranslation";

const NftWalletConnectionArea = ({
  isConnected,
  connect,
  nextPage,
  removeOwnerAddress,
  hasMore,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "nft" });
  const [address, setAddress] = useState("");

  const handleConnectWallet = () => {
    connect(address);
    setAddress("");
  };

  const openHelp = () =>
    window.open(
      "https://metamask.zendesk.com/hc/en-us/articles/360015289512",
      "_blank"
    );

  if (isConnected) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          m: "20px",
        }}
      >
        {hasMore && (
          <Typography
            onClick={nextPage}
            variant="h6"
            sx={{ color: "grey.accent1", mb: 1, mt: "24px" }}
            align="center"
          >
            {t("Load More")}
          </Typography>
        )}

        <Button
          id="disconnect-eth-wallet-btn"
          variant="contained"
          sx={{ height: 40, borderRadius: "24px" }}
          onClick={removeOwnerAddress}
        >
          <Typography variant="button">
            {t("Disconnect wallet address")}
          </Typography>
        </Button>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        m: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: 1,
          mt: "24px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ color: "grey.accent1" }} align="center">
          {t("Import your public collection")}
        </Typography>
        <Avatar
          id="nft-connect-help-btn"
          onClick={openHelp}
          sx={{
            display: "flex",
            height: "20px",
            width: "20px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "secondary.green.main",
            marginLeft: "5px",
          }}
        >
          <Typography variant="body2" sx={{ color: "grey.accent1" }}>
            ?
          </Typography>
        </Avatar>
      </Box>

      <NftWalletInputField handleChange={setAddress} value={address} />
      <Typography
        variant="body1"
        sx={{ mb: "20px", color: "grey.accent2" }}
        align="center"
      >
        {t("Add Ethereum Main Network Address")}
      </Typography>
      <Button
        id="connect-eth-wallet-btn"
        variant="contained"
        sx={{ height: 40, borderRadius: "24px" }}
        onClick={handleConnectWallet}
      >
        <Typography variant="button">{t("connect wallet address")}</Typography>
      </Button>
    </Box>
  );
};

export default NftWalletConnectionArea;
