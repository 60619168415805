import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import Menu from "../components/navigation/Menu";
import { NavigationProvider } from "../contexts/NavigationContext";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "row",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const HeaderContentLayoutWrapper = styled("div")(() => ({
  width: "100%",
  flexDirection: "column",
  display: "flex",
}));

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  overflow: "auto",
  height: "100%",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const UserLayout = () => {
  const [activeMenu, setActiveMenu] = useState(true);
  return (
    <DashboardLayoutRoot>
      <HeaderContentLayoutWrapper>
        <NavigationProvider value={setActiveMenu}>
          <Menu active={activeMenu} />
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </NavigationProvider>
      </HeaderContentLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default UserLayout;
