import axios from "axios";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const ConvertPointsAPI = axios.create({
  baseURL: apiEndpoint.convert,
  headers: {
    "content-type": "application/json",
  },
});

ConvertPointsAPI.interceptors.request.use(refreshInterceptor);

export default ConvertPointsAPI;
