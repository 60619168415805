import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import CardComponent from "../../components/card/CardComponent";
import DealsSkeleton from "../../skeletonLoading/DealsSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import useNavigationHook from "../../../../hooks/useNavigation";
import GenericError from "../../../../pages/genericError/GenericError";
import {
  nextPage,
  fetchAsyncMerchantDeals,
  resetMerchantDeals,
  status as merchantDealsStatus,
} from "../../redux/slices/deals/MerchantDeals";
import ProgressLoad from "../../../../components/progressLoad/ProgressLoad";
import EmptyDeals from "../../components/emptyDeals/EmptyDeals";
import useDealsHook from "../../../../hooks/useDealsHook";

const MerchantDeals = () => {
  let { id } = useParams();
  const { name } = useLocation()?.state;
  const setActiveMenu = useNavigationHook();
  const dealsRef = useDealsHook();

  const dispatch = useDispatch();
  const { page, deals, hasMore, status } = useSelector(
    (state) => state.merchantDeals
  );
  const limit = 10;
  useEffect(() => {
    if (status !== merchantDealsStatus.LOADING) {
      if (!dealsRef.cardSelected.current) {
        dispatch(fetchAsyncMerchantDeals({ page, limit, id }));
      } else {
        dealsRef.cardSelected.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    return () => {
      if (!dealsRef.cardSelected.current) {
        dealsRef.cardSelected.current = false;
        dispatch(resetMerchantDeals());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveMenu(false);
  });

  // useEffect(() => {
  //   if (status === dealsSearchStatus.ERROR) {
  //     setIsErrorModal(true);
  //   }
  // }, [status]);

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Box
        sx={{
          p: "0 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {status === merchantDealsStatus.LOADING ? (
          <Box sx={{ m: "16px 0" }}>
            <DealsSkeleton id="merchant-loading" />
          </Box>
        ) : status === merchantDealsStatus.ERROR ? (
          <GenericError
            id="merchant-error"
            title="Service is not available"
            body="Service is not available at the moment. You may be able to try again."
            center={true}
          />
        ) : status === merchantDealsStatus.EMPTY ? (
          <Box sx={{ m: "32px" }}>
            <EmptyDeals
              id="merchant-empty"
              title="No Available Voucher"
              subtitle="Please check back soon for exciting new offers"
            />
          </Box>
        ) : deals && status !== merchantDealsStatus.INITIAL ? (
          <Box sx={{ m: "16px 0" }}>
            <InfiniteScroll
              id="merchant-scroll"
              dataLength={deals.length}
              next={() => dispatch(nextPage(limit))}
              hasMore={hasMore}
              loader={<ProgressLoad />}
            >
              <Grid container spacing={2}>
                {deals.map((deal) => {
                  return (
                    <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={deal.id}>
                      <CardComponent deal={deal} />
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default MerchantDeals;
