import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { trackReviewSendNftCancel } from "../../mixpanel/nft";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.title !== title) {
        setTitle(document.title);
      }
    }, 50);
    return () => {
      clearInterval(intervalId);
    };
  }, [title]);

  const handleBack = () => {

    // Check for convert coin page 
    if (location.pathname === '/convert') {
      navigate('/wallet')
      return
    }

    if (window.history.state.idx <= 0) {
      window.location.replace("ascendmoney://closewebview");
      return;
    }
    if (location.pathname === "/review") {
      navigate("/convert", { state: { isBindingSuccess: false } });
    } else if (
      location.pathname === "/wallet/nfts/my-collection/complete-send-nft"
    ) {
      navigate("/wallet/nfts");
    } else {
      navigate(-1);
    }
  };

  const handleClose = () => {
    if (location.pathname === "/wallet/nfts/my-collection/review-send-nft") {
      trackReviewSendNftCancel();
    }

    if (location.pathname?.includes("/wallet/nfts/my-collection")) {
      navigate("/wallet/nfts");
    } else {
      window.location.replace("ascendmoney://closewebview");
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "space-between",
        padding: "10px",
        display: "flex",
        height: "50px",
        borderBottom: "solid 1px #bbbbbb",
        position: "sticky",
        zIndex: 1000,
        top: 0,
        backgroundColor: "background.paper",
      }}
    >
      <IconButton id="nav-bar-back-btn" size="small" onClick={handleBack}>
        <ArrowBackIosNewIcon style={{ fontSize: 20 }} />
      </IconButton>
      <Typography variant="pharagraph" sx={{ display: "flex", alignItems: "center" }}>
        {title}
      </Typography>

      <IconButton id="nav-bar-close-btn" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default NavBar;
