import en from "../assets/locales/en/translation.json";
import th from "../assets/locales/th/translation.json";
import getLanguage from "../utils/getLanguage";

const useTranslation = (_, config) => {
  const t = (text) => {
    const lang = localStorage.getItem("lang");
    return lang === "th"
      ? th[config.keyPrefix][text]
      : en[config.keyPrefix][text];
  };

  const initializeTranslation = () => {
    const validLanguages = ["en", "th"];
    let language = getLanguage();
    if (!language) {
      language = localStorage.getItem("lang");
    }
    if (language && validLanguages.includes(language)) {
      localStorage.setItem("lang", language);
    } else {
      localStorage.setItem("lang", "en");
    }
  };

  return { t, initializeTranslation };
};

export default useTranslation;
