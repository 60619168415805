import ClipboardJS from "clipboard";

const clipboard = new ClipboardJS(".btn");

clipboard.on("error", function (e) {
  console.error("Action:", e.action);
  console.error("Trigger:", e.trigger);
});

export default clipboard;
