import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TransactionAPI from "../../../../../apiConfig/TransactionsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  offset: 0,
  transactionHistory: [],
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks

export const fetchAsyncTransactionHistory = createAsyncThunk(
  "transactionHistory/fetchAsyncTransactionHistory",
  async ({ offset, limit, id }, { rejectWithValue }) => {
    try {
      const response = await TransactionAPI.get('/transactions/mine', {params: {skip: offset, limit}})
      return {
        status: response.status,
        data: response.data.items,
        limit,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  }
);

//Slices
const TransactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    nextPage: (state, action) => {
      state.offset = state.offset + action.payload;
    },
    resetTransactionHistory: (state, action) => {
      state.offset = 0;
      state.transactionHistory = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncTransactionHistory.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncTransactionHistory.fulfilled, (state, { payload }) => {
        if (
          payload.data === undefined &&
          state.transactionHistory.length !== 0
        ) {
          state.hasMore = false;
          state.status = status.LOADED;
          return;
        } else {
          if (
            payload.data === undefined ||
            (payload.data.length === 0 && state.transactionHistory.length === 0)
          ) {
            state.hasMore = false;
            state.status = status.EMPTY;
            return;
          }
        }

        if (payload.data.length < payload.limit) {
          state.transactionHistory = [
            ...state.transactionHistory,
            ...payload.data,
          ];
          state.errorMessage = null;
          state.status = status.LOADED;
          state.hasMore = false;
          return;
        }

        state.hasMore = true;
        state.transactionHistory = [
          ...state.transactionHistory,
          ...payload.data,
        ];
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncTransactionHistory.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { nextPage, resetTransactionHistory } =
  TransactionHistorySlice.actions;

export default TransactionHistorySlice.reducer;
