import axios from "axios";
// import MockAdapter from "axios-mock-adapter";
// import mockApis from "../__fakeAPI__/customerApi";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const DealsAPI = axios.create({
  baseURL: apiEndpoint.deals,
  headers: {
    "content-type": "application/json",
  },
});

DealsAPI.interceptors.request.use(refreshInterceptor);

// mockApis(new MockAdapter(DealsAPI));

export default DealsAPI;
