import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TrueIdAPI from "../../../../../apiConfig/TrueIdAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  coins: null,
  status: status.INITIAL,
  errorMessage: null,
};

export const fetchTrueIdLogin = createAsyncThunk(
  "trueId/fetchTrueIdLogin",
  async ({code}, {rejectWithValue}) => {
    try {
      const response = await TrueIdAPI.post(`/api/trueid/exchange-token`, {code});
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
)

//Slices
const slice = createSlice({
  name: "trueIdLogin",
  initialState,
  reducers: {
    resetTrueIdLogin: (state, action) => {
      state.coins = null;
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrueIdLogin.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchTrueIdLogin.fulfilled, (state, { payload }) => {
        if (payload.data === undefined) {
          state.status = status.ERROR;
          return;
        }

        state.coins = payload.data;
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchTrueIdLogin.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});
export const { resetTrueIdLogin } = slice.actions;

export default slice.reducer;