import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const TermsAndConditionsSkeleton = () => {
  var length = [];
  for (var i = 0; i < 15; i++) {
    length.push(
      <Skeleton
        key={i}
        variant="text"
        height={42}
        width="100%"
        animation="wave"
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "calc(100vh - 50px)",
        bgcolor: "background.paper"
      }}
    >
      <Skeleton
        sx={{ m: 3 }}
        key={i}
        variant="text"
        height={42}
        width={200}
        animation="wave"
      />
      {length}
    </Box>
  );
};

export default TermsAndConditionsSkeleton;
