import AuthAPI from "../../apiConfig/AuthAPI";
import { authConfig } from "../../config";
import ProfileAPI from "../../apiConfig/ProfileAPI";

const AuthContextService = {
  getUserFromKey: async (key) =>
    await AuthAPI.post(
      "protocol/openid-connect/token",
      new URLSearchParams({
        client_id: authConfig.clientId,
        grant_type: "password",
        password: key,
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    ),

  refreshUser: async (refresh_token) =>
    await AuthAPI.post(
      "protocol/openid-connect/token",
      new URLSearchParams({
        client_id: process.env.REACT_APP_CLIENT_ID,
        refresh_token,
        grant_type: "refresh_token",
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    ),

  getProfile: async (mobileNumber) =>
    await ProfileAPI.get(`profiles?mobileNumber=${mobileNumber}`),

  acceptTOS: async (language, service, version) =>
    await AuthAPI.post("abc-terms", null, {
      params: {
        language,
        service,
        version,
      },
    }),

  getQueryParam: () => {
    let url = new URL(window.location.href);
    let key = url.searchParams.get("tmwTokenRef");

    return key;
  },
  getTokenFromLocalStorage: () => localStorage.getItem("token"),
  setLocalStorageToken: (token) => {
    localStorage.setItem("token", token);
  },
  getTmwTokenRefFromLocalStorage: () => localStorage.getItem("tmwTokenRef"),
  setLocalStorageTmwTokenRef: (token) => {
    localStorage.setItem("tmwTokenRef", token);
  },
  getTrueIdTokenFromLocalStorage: () => localStorage.getItem("trueIdToken"),
  setLocalStorageTrueIdToken: (token) => {
    localStorage.setItem("trueIdToken", token);
  },
  getTrueRandomStateFromLocalStorage: () => localStorage.getItem('trueRandomState'),
  setLocalStorageTrueRandomState: (state) => {
    localStorage.setItem('trueRandomState', state)
  }
};

export default AuthContextService;
