import React from "react";
import { Box, Typography } from "@mui/material";
import useTranslation from "../../../../../hooks/useTranslation";
const TransactionEmpty = () => {
  const { t } = useTranslation("translation", { keyPrefix: "walletHistory" });
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        m: "64px 0px 100px 0px",
      }}
    >
      <Typography
        variant="body2"
        sx={{ color: "grey.accent2", textAlign: "center" }}
      >
        {t("There is no transaction")}
      </Typography>
    </Box>
  );
};

export default TransactionEmpty;
