import { Helmet } from "react-helmet-async";
import GenericError from "../genericError/GenericError";

const SessionExpiredError = () => {
  return (
    <>
      <Helmet>
        <title>ABC</title>
      </Helmet>
      <GenericError
        keyPrefix="expiredToken"
        title="Session expired"
        body="Please re-open the app to renew your session."
        buttonText="OK"
        navigateTo=""
        redirect={() => window.location.replace("ascendmoney://closewebview")}
        isHideMenu={true}
        center={true}
      />
    </>
  );
};

export default SessionExpiredError;
