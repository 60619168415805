import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import GenericErrorImg from "../../assets/images/GenericErrorImg.svg";

const ServerError = () => {
  const { t } = useTranslation("translation", { keyPrefix: "serverError" });
  return (
    <>
      {
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            minHeight: "calc(100vh - 50px)",
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                pb: "30px",
              }}
            >
              <Box
                alt="Error img not found"
                component="img"
                src={GenericErrorImg}
                sx={{
                  height: "60px",
                  width: "60px",
                }}
              />
            </Box>
            <Typography
              variant="h4"
              align="center"
              color="textPrimary"
              sx={{ pb: "25px" }}
            >
              {"500: " + t("Internal Server Error")}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              sx={{ pb: "50px" }}
            >
              {t("Please check back at a later time")}
            </Typography>
          </Container>
          <Box
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              p: "16px 16px 32px",
              backgroundColor: "background.paper",
            }}
          >
            <Button
              id="home-btn"
              color="primary"
              component={RouterLink}
              to="/"
              variant="contained"
              sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
              fullWidth
            >
              <Typography variant="button">{t("Back to Home")}</Typography>
            </Button>
          </Box>
        </Box>
      }
    </>
  );
};

export default ServerError;
