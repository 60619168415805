import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import React from "react";
import { ReactComponent as AlertIcon } from "../../assets/icons/alert.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error2.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/information.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import useTranslation from "../../hooks/useTranslation";

const renderIcon = (iconName) => {
  if (iconName === 'warning') {
    return <AlertIcon />
  } else if (iconName === 'info') {
    return <InfoIcon />
  } else if (iconName === 'success') {
    return <SuccessIcon />
  } else {
    return <ErrorIcon />
  }
}

const Prompt = ({
  icon = "",
  image = "",
  imageSx = {},
  isCloseButtonVisible = false,
  titleSx={},
  subTitleSx={},
  onCloseIcon= () => {},
  isVisible,
  onClose,
  onClose2,
  title,
  rawTitle = '',
  seccondTitle = '',
  subTitle,
  rawSubTitle = '',
  buttonText,
  buttonText2,
  keyPrefix = "Prompt",
  acceptButtonSx={}
}) => {
  const { t } = useTranslation("translation", { keyPrefix });
  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          width: "100%",
          minHeight: "258px",
          m: "16px",
          justifyContent: "space-between",
          borderRadius: "16px",
          p: "24px",
          overflowY: 'auto',
          maxHeight: '100%'
        }}
      >

        {
          isCloseButtonVisible && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end'
              }}
              >
                <IconButton 
                  data-testid='btn-close-top-right'
                  id='btn_xTopRight'
                  onClick={onCloseIcon}>
                  <CloseIcon />
                </IconButton>
            </Box>
          )
        }


        {icon && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mb: "21px",
            }}
          >
            {renderIcon(icon)}
          </Box>
        )}

        {
          image && (
            <Box 
              id="img_logoPopup"
              component='img'
              src={image}
              sx={{
                marginTop: '6px',
                marginBottom: '24px',
                alignSelf: 'center',
                ...imageSx
              }} />
          )
        }

        <Typography
          id='lbl_titlePopup' 
          variant="h6" 
          sx={{ 
            mb: "8px", 
            ...titleSx 
          }} 
          align="center"
        >
          {
            title 
            ? t(title) 
            : (rawTitle || '')
          }
          {
            t(seccondTitle)
            ? <span style={{display: 'block'}}>
                {t(seccondTitle)}
              </span>
            : ''
          }
        </Typography>
        <Typography
          id='lbl_contentPopup'
          variant="body2"
          sx={{
            mb: "16px",
            ...subTitleSx
          }}
          align="center"
        >
          {subTitle ? t(subTitle) : (rawSubTitle || '')}
        </Typography>

        {buttonText2 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              id='btn_1Popup'
              data-testid="btn_1Popup"
              color="primary"
              variant="outlined"
              sx={{ borderRadius: 16, width: "47%", height: "50px" }}
              onClick={onClose}
            >
              <Typography variant="button">{t(buttonText)}</Typography>
            </Button>
            <Button
              id='btn_2Popup'
              data-testid="btn_2Popup"
              color="primary"
              variant="contained"
              sx={{ borderRadius: 16, width: "47%", height: "50px", ...acceptButtonSx }}
              onClick={onClose2}
            >
              <Typography variant="button">{t(buttonText2)}</Typography>
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              mb: 1,
            }}
          >
            <Button
              id='btn_1Popup'
              data-testid="btn_1Popup"
              color="primary"
              variant="contained"
              sx={{ borderRadius: 16, height: "50px", width: "100%", ...acceptButtonSx }}
              onClick={onClose}
            >
              <Typography variant="button">{t(buttonText)}</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default Prompt;
