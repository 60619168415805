import React from "react";
import { List, Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import VoucherCard from "../voucherCard/VoucherCard";
import useVouchers from "../../hooks/useVouchers";
import ProgressLoad from "../../../../components/progressLoad/ProgressLoad";
import { status as vouchersStatus } from "../../redux/slices/vouchers/vouchers";
import VouchersSkeleton from "../../skeletonLoading/VouchersSkeleton";
import VouchersError from "./VouchersError";
import VouchersEmpty from "./VouchersEmpty";

const TransactionHistoryList = () => {
  const { vouchers, status, hasMore, handleNextPage } = useVouchers();

  const renderVoucherList = () => {
    return vouchers.map((item, index) => {
      return <VoucherCard key={index} data={item} />;
    });
  };

  return (
    <Box sx={{ px: "16px" }}>
      {status === vouchersStatus.LOADING && vouchers.length <= 0 ? (
        <VouchersSkeleton />
      ) : status === vouchersStatus.ERROR ? (
        <VouchersError />
      ) : status === vouchersStatus.EMPTY ? (
        <VouchersEmpty />
      ) : (
        <InfiniteScroll
          dataLength={vouchers.length}
          next={handleNextPage}
          hasMore={hasMore}
          loader={<ProgressLoad />}
        >
          <List>{renderVoucherList()}</List>
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default TransactionHistoryList;
