import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import useTranslation from "../../../../../hooks/useTranslation";

const NftLoadingOverlay = ({ isShown }) => {
  const { t } = useTranslation("translation", { keyPrefix: "nft" });
  return (
    <Modal
      open={isShown}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          width: "calc(100vw - 20px)",
          justifyContent: "center",
          alignItems: "center",
          height: "125px",
        }}
      >
        <Typography variant="h6" sx={{ color: "grey.accent1" }}>
          {t("Searching")}
        </Typography>
      </Box>
    </Modal>
  );
};

export default NftLoadingOverlay;
