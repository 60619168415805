import React from "react";
import { Box, Avatar, Skeleton } from "@mui/material";

const MerchantListSkeleton = () => {
  var length = [];
  for (var i = 0; i < 4; i++) {
    length.push(i);
  }
  return (
    <Box
      sx={{
        minHeight: "120px",
        border: 0,
        borderColor: "grey.accent3",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "16px",
        overflow: "auto",
        p: "0 5px",
        bgcolor: "background.paper"
      }}
    >
      {length.map((merchant) => {
        return (
          <Box
            key={merchant}
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "auto",
            }}
          >
            <Avatar
              sx={{
                height: "56px",
                width: "56px",
                mb: "8px",
              }}
            >
              <Skeleton
                animation="wave"
                variant="circular"
                height="56px"
                width="56px"
              />
            </Avatar>
            <Skeleton animation="wave" width={55} height={"20px"} />
          </Box>
        );
      })}
    </Box>
  );
};

export default MerchantListSkeleton;
