import React from "react";
import useTranslation from "../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import MerchantList from "../../features/deals/components/merchants/MerchantList";
import AllDeals from "../../features/deals/components/allDeals/AllDeals";
import CarouselBanner from "../../features/deals/components/carousel/CarouselBanner";
import useNavigationHook from "../../hooks/useNavigation";
import { trackVisitMarketplacePage } from "../../mixpanel";
// import FirstCircle from "../../assets/images/Marketplace/FirstCircle.svg";
// import { ReactComponent as SecondCircle } from "../../assets/images/Marketplace/SecondCircle.svg";

const Marketplace = () => {
  const { t } = useTranslation("translation", { keyPrefix: "marketplace" });
  const setActiveMenu = useNavigationHook();

  React.useEffect(() => {
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    trackVisitMarketplacePage();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Marketplace")}</title>
      </Helmet>
      <Box
        sx={{
          // backgroundImage: `url(${FirstCircle})`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "175px 360px",
          // backgroundAttachment: "fixed",
          // backgroundPosition: "0 82px",
          pt: "32px",
        }}
      >
        {/* <Box
          sx={{
            position: "fixed",
            right: 0,
            bottom: "-30px",
            zIndex: -1,
          }}
        >
          <SecondCircle />
        </Box> */}
        <Box sx={{ mb: "16px", p: "0 16px" }}>
          {/* <Button
            sx={{
              mb: "24px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              border: 1,
              borderColor: "#BBBBBB",
              borderRadius: "4px",
              height: "40px",
              backgroundColor: "background.paper"
            }}
            onClick={() => {
              navigate("/search-deals");
            }}
            id="searchButton"
          >
            <SearchIcon color="primary.main" />
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
              }}
              color="#868686"
            >
              {t("Search by voucher or merchant name")}
            </Typography>
          </Button> */}
          <Box
            sx={{
              mb: "16px",
            }}
          >
            <CarouselBanner />
            <MerchantList />
          </Box>
        </Box>
        <Box sx={{ mb: "16px" }}>
          <AllDeals />
        </Box>
      </Box>
    </>
  );
};

export default Marketplace;
