import React from "react";
import { Box, Typography, Card, CardActionArea, Skeleton } from "@mui/material";
import { styled, experimental_sx as sx } from "@mui/system";
import { useNavigate } from "react-router-dom";
import NftThumbnail from "../nftThumbnail/NftThumbnail";

const CardContainer = styled(Card)(
  sx({
    bgcolor: "background.default",
    border: "1px solid",
    borderColor: "grey.accent3",
    borderRadius: "16px",
    boxShadow: 0,
  }),
);

const NftCard = ({ collection, token, allTokens }) => {
  const navigate = useNavigate();

  const handleOnClickCard = () => {
    navigate("/wallet/nfts/my-collection", {
      state: {
        collection,
        token,
        allTokens,
      },
    });
  };

  const [imageSkeletonWidth, setImageSkeletonWidth] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current) {
      setImageSkeletonWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  const CardSkeleton = () => {
    return (
      <>
        <CardContainer>
          <Box
            sx={{
              p: "12px",
            }}
          >
            <Skeleton
              ref={ref}
              variant="rectangular"
              width="100%"
              height={imageSkeletonWidth}
              sx={{
                border: "1px solid",
                borderColor: "grey.accent3",
                borderRadius: "16px",
                mb: "16px",
              }}
              data-testid="image-skeleton"
            />
            <Box>
              <Skeleton variant="text" width={"80px"} />
            </Box>
          </Box>
        </CardContainer>
      </>
    );
  };

  if (!token) {
    return <CardSkeleton />;
  }

  return (
    <>
      <CardContainer>
        <CardActionArea
          onClick={handleOnClickCard}
          sx={{
            p: "12px",
          }}
        >
          <Box sx={{ mb: "16px" }}>
            <NftThumbnail token={token} />
          </Box>
          <Box>
            <Typography variant="subtitle">{token.metadata?.name}</Typography>
          </Box>
        </CardActionArea>
      </CardContainer>
    </>
  );
};

export default NftCard;
