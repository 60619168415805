import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { paletteLight } from "../../../../theme/index";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import useSendCoin from "../../hooks/useSendCoin";
import useNavigationHook from "../../../../hooks/useNavigation";
import { useNavigate } from "react-router-dom";

const SendCoin = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [disabled, setDisabled] = useState(true);
  const setActiveMenu = useNavigationHook();
  const { loading, response, error, sendCoin } = useSendCoin();

  useEffect(() => {
    setActiveMenu(false);
  });

  const toaster = (message, isError) =>
    toast(message, {
      duration: 2000,
      style: {
        textAlign: "start",
        padding: "12px, 16px, 12px, 16px",
        color: "#FFFFFF",
        backgroundColor: isError ? "#FF6961" : "#77DD77",
        width: "100%",
        borderRadius: "5px",
      },
    });

  useEffect(() => {
    if (amount && phoneNumber) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }, [amount, phoneNumber]);

  useEffect(() => {
    if (error) {
      toaster(error.message, true);
    }
  }, [error]);

  useEffect(() => {
    if (response?.status === 201 || response?.status === 200) {
      navigate(-1);
    }
  }, [response]);

  const handleSendCoin = () => {
    sendCoin(phoneNumber, amount);
  };

  return (
    <>
      <Helmet>
        <title>{"Send Coin"}</title>
      </Helmet>
      <Box sx={{ height: "calc(100vh - 50px)", p: "10px 20px" }}>
        <CssTextField
          placeholder="Phone Number"
          sx={{ display: "flex", flex: 1, mb: "10px" }}
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          autoComplete="off"
          type="number"
        />
        <CssTextField
          placeholder="Amount"
          sx={{ display: "flex", flex: 1, mb: "10px" }}
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          autoComplete="off"
          type="number"
        />
      </Box>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            position: "relative",
            bottom: "48px",
          },
        }}
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ message }) => (
              <>
                <div>{message}</div>
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <Box
        sx={{
          py: 2,
          px: "20px",
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          fullWidth
          sx={{ borderRadius: "24px", flex: 1 }}
          onClick={handleSendCoin}
          disabled={disabled || loading}
        >
          {loading ? (
            <CircularProgress color="secondary" size="24px" />
          ) : (
            <Typography variant="body1">SEND</Typography>
          )}
        </Button>
      </Box>
    </>
  );
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: paletteLight.secondary.main,
  },

  "& ::placeholder": {
    fontSize: "14px",
  },

  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: `1px solid ${paletteLight.secondary.main}`,
    },
    input: {
      padding: "10px 12px",
      "caret-color": paletteLight.secondary.main,
      fontSize: "14px",
    },
  },
});

export default SendCoin;
