import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as DefaultImage } from "../../../../../assets/images/default-image.svg";
import useTranslation from "../../../../../hooks/useTranslation";

const EmptyMyCollection = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box sx={{ mb: "24px" }}>
        <DefaultImage />
      </Box>
      <Box sx={{ mb: "8px" }}>
        <Typography variant="h6">
          {t("No NFT Collection on ABC Network")}
        </Typography>
      </Box>
      <Box sx={{ mb: "18px" }}>
        <Typography variant="body2" sx={{ color: "grey.accent2" }}>
          {t("Your NFT collection on ABC Network will show here")}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyMyCollection;
