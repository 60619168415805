import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chip,
  Collapse,
  List,
  ListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { orderBy } from "lodash";
import GenericErrorImg from "../../../../../assets/images/GenericErrorImg.svg";
import NftCard from "../../nftCard/NftCard";
import { status as NftMyCollectionStatus } from "../../../redux/slices/nftMyCollection/NftMyCollection";
import { fetchAsyncNft } from "../../../redux/slices/nftMyCollection/NftMyToken";

const NftSection = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 16px;
`;

const MyCollection = () => {
  const dispatch = useDispatch();
  const { collections, status: collectionsStatus } = useSelector(
    state => state.nftMyCollection,
  );

  const { tokens } = useSelector(state => state.nftMyToken);

  const [openCollapse, setOpenCollapse] = useState();

  useEffect(() => {
    if (
      collectionsStatus === NftMyCollectionStatus.LOADED &&
      collections?.length
    ) {
      let openCollpaseData = {};
      collections.forEach(collection => {
        openCollpaseData = { ...openCollpaseData, [collection.address]: false };
      });

      setOpenCollapse(openCollpaseData);
    }
  }, [collections, collectionsStatus, dispatch]);

  useEffect(() => {
    if (
      collectionsStatus === NftMyCollectionStatus.LOADED &&
      collections?.length
    ) {
      collections.forEach(collection => {
        dispatch(
          fetchAsyncNft({
            address: collection.address,
            tokens: collection.tokens,
          }),
        );
      });
    }
  }, [collections, collectionsStatus, dispatch]);

  const handleOnOpenCollapse = id => {
    setOpenCollapse(op => {
      return {
        ...op,
        [id]: !op[id],
      };
    });
  };

  const CreatorItem = ({ id, data }) => {
    return (
      <ListItem
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => handleOnOpenCollapse(id)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            alt={`Collection's logo`}
            component="img"
            src={data.metadata.image}
            sx={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = GenericErrorImg;
            }}
          />
          <Box sx={{ ml: "8px" }}>
            <Typography variant="subtitle">{data.name}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip
            label={tokens[id] ? tokens[id].length : ""}
            size="small"
            sx={{ minWidth: "40px", fontSize: "0.875rem" }}
          />
          <Box sx={{ display: "flex", alignItems: "center", ml: "10px" }}>
            {openCollapse?.[id] ? (
              <ExpandLess sx={{ fontSize: "1.75rem" }} />
            ) : (
              <ExpandMore sx={{ fontSize: "1.75rem" }} />
            )}
          </Box>
        </Box>
      </ListItem>
    );
  };

  const CreatorItemSkeleton = () => {
    return (
      <ListItem
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        data-testid="skeleton-list-item"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton
            variant="circular"
            sx={{
              height: "40px",
              width: "40px",
            }}
          />
          <Box sx={{ ml: "8px" }}>
            <Skeleton variant="text" width={"100px"} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton variant="text" width={"40px"} />
          <Skeleton variant="text" width={"20px"} sx={{ ml: "10px" }} />
        </Box>
      </ListItem>
    );
  };

  const Creator = ({ id, data }) => {
    return (
      <>
        <CreatorItem id={id} data={data} />
        <Collapse
          in={openCollapse ? openCollapse[id] : false}
          timeout="auto"
          unmountOnExit
        >
          <Box sx={{ bgcolor: "grey.accent4" }}>
            <NftSection>
              {tokens[id] ? (
                orderBy(tokens[id], ["tokenID"], ["desc"]).map(item => (
                  <NftCard
                    key={item.tokenID}
                    token={item}
                    allTokens={tokens}
                    collection={collections.find(
                      collection => collection.address === id,
                    )}
                  />
                ))
              ) : (
                <>
                  <NftCard />
                  <NftCard />
                </>
              )}
            </NftSection>
          </Box>
        </Collapse>
      </>
    );
  };

  const CreatorList = () => {
    return (
      <>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nft-list"
        >
          <>
            {collectionsStatus === NftMyCollectionStatus.LOADING ? (
              <>
                <CreatorItemSkeleton />
                <CreatorItemSkeleton />
                <CreatorItemSkeleton />
              </>
            ) : (
              collections
                .slice()
                .reverse()
                .map(collection => {
                  return (
                    <Creator
                      key={collection.address}
                      id={collection.address}
                      data={collection}
                    />
                  );
                })
            )}
          </>
        </List>
      </>
    );
  };

  return (
    <>
      <CreatorList />
    </>
  );
};

export default MyCollection;
