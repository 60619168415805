import { Avatar, Box, Button, Divider, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import jwtDecode from 'jwt-decode';
import useNavigationHook from "../../../../hooks/useNavigation";
import useTranslation from "../../../../hooks/useTranslation";
import Coins from '../../../../assets/icons/coins.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from '../../../../redux/store/configureStore'
import { fetchAsyncCoins, status as CoinsStatus } from '../../redux/slices/coins/Coins';
import CheckIcon from '../../../../assets/images/MyWallet/Check_Base.png'
import { formatDateTime } from '../../../../utils/dateTimeFormatter';
import { trackConvertCoinsComplete } from '../../../../mixpanel';
import AccessTokenService from '../../../../contexts/authContext/AuthContextServices';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs';
dayjs.extend(utc)
dayjs.extend(timezone)
export const ConvertCompleteLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
`

export const DetailLine = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

export const DetailDivinder = styled(Divider)`
  width: 100%;
  border-color: #E1E1E1;
`

const ConvertCoinsComplete = () => {
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const convert = useLocation().state?.convert
  const { coins, status } = useSelector((state) => state.coins); 
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });

  const {
    amount,
    fromName,
    fromImageIconUrl,
    dateTime,
    transactionId,
    convertPoints,
    exchangeRate
  } = convert

  const handleDoneClick = (e) => {
    e.preventDefault();
    navigate('/wallet')
  }

  const getShowDate = (dateTime) => {
    const offsetMin = dayjs().utcOffset()
    if (offsetMin >= 0) {
      return formatDateTime( dayjs(dateTime).add(offsetMin, 'minute').format(), 'DD MMM YYYY, HH:mm')
    } else {
      return formatDateTime( dayjs(dateTime).subtract(offsetMin, 'minute').format(), 'DD MMM YYYY, HH:mm')
    }
  }

  useEffect(() => {
    setActiveMenu(false);
    if (status !== CoinsStatus.LOADING) {
      dispatch(fetchAsyncCoins());
    }
  }, []);

  useEffect(() => {
    if (coins) {
      let partnerId = "";
      let partnerName = "";
      if (fromName === "True Point") {
        const trueIdToken = AccessTokenService.getTrueIdTokenFromLocalStorage();
        partnerId = jwtDecode(trueIdToken).sub;
        partnerName = "TruePoint";
      }
      trackConvertCoinsComplete(
        partnerName,
        parseFloat(convertPoints, 10),
        parseFloat(amount, 10),
        exchangeRate,
        partnerId,
        parseFloat(coins.balance, 10)
      );
    }
  }, [coins, amount, convertPoints, exchangeRate, fromName]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: "32px 16px",
        height: "calc(100vh - 50px)",
      }}
    >
      <Helmet>
        <title>{t("Complete")}</title>
      </Helmet>
      <ConvertCompleteLayout>
          <Box 
            component='img'
            src={CheckIcon} />
          <Typography id="successTitle" variant='subtitle'>
            {t("Successfully Converted")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              alt="Coins"
              component="img"
              src={Coins}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            />
            <Typography id="amountState" variant='h6'>
              {amount} ABC
            </Typography>
          </Box>
          <DetailDivinder />
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('From')}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Avatar 
                alt={fromName} 
                src={fromImageIconUrl}
                style={{
                  width: '24px',
                  height: '24px', 
                  marginRight: '8px',
                  border: '1px solid #E1E1E1'
                }}
              />
              <Typography 
                variant='body2' 
                style={{lineHeight: '24px'}}
              >
                {fromName}
              </Typography>
            </Box>
          </DetailLine>
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('Date & Time')}
            </Typography>
            <Typography variant='body2'>
              { getShowDate(dateTime) }
              {/* 12 Jan 2022, 13:09 */}
            </Typography>
          </DetailLine>
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('Transaction ID')}
            </Typography>
            <Typography 
              variant='body2'
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '120px'
              }}
            >
              {transactionId}
            </Typography>
          </DetailLine>
          <DetailDivinder />
          <DetailLine style={{marginTop: '8px'}}>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('Total balance')}
            </Typography>
            <Box sx={{ display: "flex" }}>
            <Box
              alt="Coins"
              component="img"
              src={Coins}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            />

            {
              status === CoinsStatus.LOADING ? (
                <Skeleton animation="wave" width="20%" />
              ) : status === CoinsStatus.ERROR ? (
                ''
              ) : status === CoinsStatus.EMPTY ? (
                ''
              ) : coins && status !== CoinsStatus.INITIAL ? (
                <Typography variant='subtitle'>
                  {coins.balance} ABC
                </Typography>
              ) : null
            }
            
          </Box>
          </DetailLine>
      </ConvertCompleteLayout>
      <Button 
        id="done-btn"
        data-testid="done-btn"
        variant="contained"
        sx={{ height: 48, width: "100%", borderRadius: "24px" }}
        fullWidth
        onClick={handleDoneClick}
      >
        {t("Done")}
      </Button>
    </Box>
  )
}

export default ConvertCoinsComplete