import React from "react";
import { List, Box, Typography } from "@mui/material";
import moment from "moment";
import { formatDateTime } from "../../../../../utils/dateTimeFormatter";
import useTranslation from "../../../../../hooks/useTranslation";

const TransactionHistoryGroup = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "walletHistory" });
  const { title } = props;
  const currentDate = formatDateTime(moment(), "DD MMM YYYY");
  return (
    <Box sx={{ m: "0px 16px" }}>
      <Typography variant="h6" sx={{ m: "5px 0px", color: "grey.accent2" }}>
        {currentDate === title ? t("Today") : title}
      </Typography>
      <List>{props.children}</List>
    </Box>
  );
};
export default TransactionHistoryGroup;
