import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as EmptySearchIcon } from "../../../../assets/icons/empty-search.svg";
import useTranslation from "../../../../hooks/useTranslation";

const EmptyDeals = ({ title, subtitle, keyPrefix = "EmptyDeals" }) => {
  const { t } = useTranslation("translation", { keyPrefix });
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "220px",
      }}
    >
      <Box sx={{ mt: "24px", mb: "16px" }}>
        <EmptySearchIcon />
      </Box>
      <Typography variant="h6" sx={{ mb: "16px" }} align="center">
        {t(title)}
      </Typography>
      <Typography variant="body1" align="center">
        {t(subtitle)}
      </Typography>
    </Box>
  );
};

export default EmptyDeals;
