import React from "react";
import { Box, Avatar, Skeleton } from "@mui/material";

const RedemptionMethodSkeleton = () => {
  return (
    <Box
      data-testid="redemption-loading"
      sx={{
        height: "112px",
        background: "background.paper",
        border: 1,
        borderColor: "grey.accent3",
        boxShadow: 1,
        borderRadius: "16px",
        mt: "8px",
        bgcolor: "background.paper"
      }}
    >
      <Box sx={{ m: "16px 16px 16px 16px" }}>
        <Skeleton animation="wave" width={150} height={"20px"} />

        <Box
          sx={{
            display: "flex",
            mt: "8px",
          }}
        >
          <Avatar
            sx={{
              height: "24px",
              width: "24px",
              mr: "16px",
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              height="24px"
              width="24px"
            />
          </Avatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Skeleton animation="wave" width={80} height={"20px"} />
            <Skeleton animation="wave" width={120} height={"20px"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RedemptionMethodSkeleton;
