import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DealsAPI from "../../../../../apiConfig/DealsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  page: 1,
  deals: [],
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks

export const fetchAsyncMerchantDeals = createAsyncThunk(
  "deals/fetchAsyncMerchantDeals",
  async ({ page, limit, id }, { rejectWithValue }) => {
    try {
      let title;
      if (localStorage.getItem("lang") === "th") title = "titleTh";
      else title = "titleEn";
      const response = await DealsAPI.get(
        `/deals?page=${page}&limit=${limit}&merchant=${id}&sortBy=${title}`
      );
      return {
        status: response.status,
        data: response.data.entries,
        totalPages: response.data.totalPages,
        limit: limit,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  }
);

//Slices
const MerchantDealsSlice = createSlice({
  name: "merchantDeals",
  initialState,
  reducers: {
    nextPage: (state, action) => {
      state.page = state.page + 1;
    },
    resetMerchantDeals: (state, action) => {
      state.page = 1;
      state.deals = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncMerchantDeals.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncMerchantDeals.fulfilled, (state, { payload }) => {
        if (!payload.data || payload.data.length === 0) {
          state.errorMessage = null;
          state.hasMore = false;
          state.status = status.EMPTY;
          return;
        }

        state.hasMore = state.page < payload.totalPages;
        state.deals = [...state.deals, ...payload.data];
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncMerchantDeals.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { nextPage, resetMerchantDeals } = MerchantDealsSlice.actions;

export default MerchantDealsSlice.reducer;
