import React, { useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { trackVisitHomePage, trackTMNVisitHomePage, trackHomeQuickAction, QUICK_ACTION_TYPE } from "../../mixpanel";
import { useDispatch, useSelector } from "../../redux/store/configureStore";
import {
  fetchAsyncCoins,
  status as CoinsStatus,
} from "../../features/wallet/redux/slices/coins/Coins";
import useAuth from "../../hooks/useAuth";
import useNavigationHook from "../../hooks/useNavigation";
import BalanceCard from "./balanceCard/BalanceCard";
import ProfileSection from "./profileSection/ProfileSection";

import useTranslation from "../../hooks/useTranslation";
import {
  setVisitedConsentPage,
  fetchAsyncConsent,
  status as ConsentStatus,
  resetConsent,
} from "../../redux/slices/consent/Consent";
import { ReactComponent as RedeemIcon } from "../../assets/icons/home/quick-action-redeem.svg";
import { ReactComponent as ConvertIcon } from "../../assets/icons/home/quick-action-convert.svg";
import { ReactComponent as NftIcon } from "../../assets/icons/home/quick-action-nft.svg";

import  MarketPlaceImageTH  from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-01.jpg"
import  CoinWebSiteTH       from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-02.jpg"
import  ABCfaqTH            from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-03.jpg"
import  MyWalletNFTTH       from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-04.jpg"

import MarketPlaceImageEN   from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-05.jpg"
import CoinWebSiteEN        from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-06.jpg"
import ABCfaqEN             from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-07.jpg"
import MyWalletNFTEN        from "../../assets/images/Home/KV_ABC_Banner_186x213pix_V3-08.jpg"
import { featureFlag } from "../../config";

const Homepage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "homeScreen" });
  const lang = localStorage.getItem("lang");
  const { user } = useAuth();
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const { coins, status } = useSelector(state => state.coins);
  const {
    visitedConsentPage,
    consents,
    status: consentStatus,
  } = useSelector(state => state.consent);

  const dispatch = useDispatch();

  const bannerList = [
    {
      id: 'banner-marketplace',
      src: {
        th: MarketPlaceImageTH,
        en: MarketPlaceImageEN,
      },
      externalLink: '',
      internalLink: '/wallet'
    },
    {
      id: 'banner-coinwebsite',
      src: {
        th: CoinWebSiteTH,
        en: CoinWebSiteEN,
      },
      externalLink: 'https://abccoin.digital',
      internalLink: ''
    },
    {
      id: 'banner-abc-faq',
      src: {
        th: ABCfaqTH,
        en: ABCfaqEN,
      },
      externalLink: 'https://walletsupport.ascendbit.net',
      internalLink: ''
    },
    {
      id: 'banner-mywallet-nft',
      src: {
        th: MyWalletNFTTH,
        en: MyWalletNFTEN,
      },
      externalLink: '',
      internalLink: featureFlag.enableNftMyCollection
        ? "/wallet/nfts"
        : "/wallet/nfts/other-collectibles",
    },
  ]

  const handleBannerClick = ({externalLink, internalLink}) => {
    if (externalLink) {
      window.open(externalLink, "_blank", "width=400,height=600")
    } else {
      navigate(internalLink)
    }
  }

  useEffect(() => {
    if (visitedConsentPage) {
      setActiveMenu(true);
    } else {
      setActiveMenu(false);
    }
  }, [setActiveMenu, visitedConsentPage]);

  useEffect(() => {
    if (visitedConsentPage) {
      dispatch(resetConsent());
      return;
    }
    if (consentStatus === ConsentStatus.INITIAL) {
      dispatch(fetchAsyncConsent("open-app"));
    } else if (consentStatus === ConsentStatus.LOADED) {
      dispatch(setVisitedConsentPage());
      if (consents?.length) {
        navigate("/consent", { replace: true });
      }
    } else if (consentStatus === ConsentStatus.ERROR) {
      dispatch(setVisitedConsentPage());
    }
  }, [consents, consentStatus, visitedConsentPage, dispatch, navigate]);

  useEffect(() => {
    if (visitedConsentPage) {
      if (status === CoinsStatus.INITIAL) {
        dispatch(fetchAsyncCoins());
      }
    }
  }, [dispatch, status, visitedConsentPage]);

  useEffect(() => {
    if (coins) {
      trackVisitHomePage(coins);
      trackTMNVisitHomePage(coins);
    }
  }, [coins]);

  // useEffect(() => {
  //   console.log('location:', location)
  // }, [])

  const handleQuickActionClick = (pathname, search, quickActionType) => {
    navigate({
      pathname,
      search
    })
    trackHomeQuickAction(quickActionType)
  }

  if (!visitedConsentPage) {
    return <div></div>;
  }

  return (
    <>
      <Helmet>
        <title>{t("Home")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 120px)",
        }}
      >
        <ProfileSection user={user} />
        <BalanceCard
          isLoading={status === CoinsStatus.LOADING}
          isError={status === CoinsStatus.ERROR}
          balance={coins?.balance}
        />
        <Box
          sx={{
            backgroundColor: "background.paper",
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            padding: '16px 0'
          }}
        >
          <IconButton 
            data-testid='btn-quick-action-marketplace'
            sx={{display: 'flex', flexDirection: 'column'}}
            onClick={() => handleQuickActionClick('/marketplace', '', QUICK_ACTION_TYPE["Buy Voucher"])}
            >
            <RedeemIcon />
            <Typography 
              variant="subtitle1" 
              sx={{
                color: '#333333',
                marginTop: '8px',
              }}>
                {t("Redeem")}
            </Typography> 
          </IconButton>
          <IconButton 
            data-testid='btn-quick-action-p2c'
            sx={{display: 'flex', flexDirection: 'column'}}
            onClick={() => handleQuickActionClick('/wallet', '?openBinding=true', QUICK_ACTION_TYPE.P2C)}
            >
            <ConvertIcon />
            <Typography 
              variant="subtitle1" 
              sx={{
                color: '#333333',
                marginTop: '8px',
              }}>
                {t("Convert")}
            </Typography> 
          </IconButton>
          <IconButton
            data-testid="btn-quick-action-nft"
            sx={{ display: "flex", flexDirection: "column" }}
            onClick={() =>
              handleQuickActionClick(
                featureFlag.enableNftMyCollection
                  ? "/wallet/nfts"
                  : "/wallet/nfts/other-collectibles",
                "",
                QUICK_ACTION_TYPE.NFT,
              )
            }
          >
            <NftIcon />
            <Typography 
              variant="subtitle1" 
              sx={{
                color: '#333333',
                marginTop: '8px',
              }}>
                {t("NFT")}
            </Typography> 
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            backgroundColor: "grey.accent4",
            flexDirection: "column",
            mt: "25px",
            pt: "39px",
          }}
        >
          <Typography
            variant="h6"
            color="grey.accent1"
            sx={{ px: "16px" }}
          >
            {t("Learn More")}
          </Typography>
          <Box
            sx={{
              padding: '16px 16px 0 16px',
              display: 'flex',
              gap: '16px',
              width: "100%",
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {
              bannerList.map(({
                id,
                src,
                internalLink,
                externalLink
              }) => {
                return (
                  <Box 
                    key={`banner-item-${id}`}
                    data-testid={id}
                    sx={{
                      width: '186px',
                      height: '213px',
                      borderRadius: '16px',
                    }}
                    component='img'
                    src={src[lang]}
                    onClick={() => handleBannerClick({internalLink, externalLink})}
                  />
                )
              })
            }
           
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Homepage;
