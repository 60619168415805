import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import { ReactComponent as EmptySearchIcon } from "../../../../assets/icons/empty-search.svg";

const VoucherEmpty = () => {
  const { t } = useTranslation("translation", { keyPrefix: "vouchers" });
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/marketplace");
  };

  return (
    <Box
      sx={{
        minHeight: "216px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.paper",
        flexDirection: "column",
        px: "20px",
      }}
    >
      <EmptySearchIcon />
      <Typography variant="h6" sx={{ mb: 1, mt: "24px" }} align="center">
        {t("No voucher yet")}
      </Typography>
      <Box sx={{ mb: "18px" }}>
        <Typography variant="body1" sx={{ mb: 1 }} align="center">
          {t(
            "When you redeem a voucher from the marketplace, you’ll see it here.",
          )}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          sx={{ color: "primary.main" }}
          onClick={handleOnClick}
        >
          {t("GO TO MARKETPLACE")}
        </Button>
      </Box>
    </Box>
  );
};

export default VoucherEmpty;
