import React, { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import WalletVoucherTab from "../../features/wallet/components/walletVoucherTab/WalletVoucherTab";
import { Box } from "@mui/material";
// import WalletBg from "../../assets/images/WalletBg.svg";
// import { ReactComponent as WalletFooter } from "../../assets/images/WalletFooter.svg";
import useNavigationHook from "../../hooks/useNavigation";

const Wallet = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "walletVoucherTab",
  });
  const setActiveMenu = useNavigationHook();
  const [activeTab, setActiveTab] = useState(
    window.location.pathname === "/wallet"
      ? 0
      : window.location.pathname === "/wallet/my-vouchers"
      ? 1
      : 2
  );

  React.useEffect(() => {
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("My Wallet")}</title>
      </Helmet>
      {/* <Box
        sx={[
          {
            transitionDuration: "500ms",
            backgroundImage: "url(" + WalletBg + ")",
            backgroundSize: "200vw",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: activeTab ? "-100vw" : "0vw",
            minHeight: `calc(100vh - 119px)`,
            lineHeight: 0,
          },
        ]}
      > */}
      <Box
        sx={{
          pt: "32px",
        }}
      >
        <WalletVoucherTab onChange={setActiveTab} activeTab={activeTab} />
        <Outlet />
      </Box>
      {/* </Box> */}
      {/* <Box
        style={{
          overflow: "hidden",
          position: "absolute",
          bottom: 0,
          zIndex: -1,
          lineHeight: 0,
        }}
      >
        {activeTab ? <></> : <WalletFooter width={"100vw"} />}
      </Box> */}
    </>
  );
};

export default Wallet;
