import { mixpanel } from "./mixpanelConfig";
import jshashes from "jshashes";
import { formatDateTime } from "../utils/dateTimeFormatter";

export const trackOnboarding = () => {
  mixpanel.reset();
  mixpanel.track("Visit Signup Landing Page");
};

export const trackLoginSignup = (user, isSignup = false) => {
  const hashedUserId = new jshashes.SHA256().hex(user.id.toString());

  if (
    localStorage.getItem("mixpanel-last-user") !== hashedUserId &&
    isSignup === false
  ) {
    mixpanel.reset();
  }

  if (isSignup) {
    mixpanel.alias(hashedUserId);
    mixpanel.identify(hashedUserId);
  } else {
    mixpanel.identify(hashedUserId);
  }
  mixpanel.register({
    "Linked Account": ["TrueMoney"],
  });
  mixpanel.people.set({
    $user_id: hashedUserId,
    "TMN ID": new jshashes.SHA256().hex(user.tmwID),
    "Registration Method": "TrueMoney",
    "Registration Date": user.createdAt,
    "Linked Account": ["TrueMoney"],
    "Birth Year": user.dateOfBirth?.split("-")[0],
  });
  mixpanel.people.union("Channel Used", "TrueMoney");
  isSignup
    ? mixpanel.track("Completed Signup")
    : mixpanel.track("Log In", { "Login Method": "TrueMoney" });

  localStorage.setItem("mixpanel-last-user", hashedUserId);
};

export const trackVisitHomePage = coins => {
  mixpanel.people.set({
    "ABC Balance": coins.balance,
  });
  mixpanel.track("Visit ABC Home Page", {
    "ABC Balance": coins.balance,
  });
};

export const trackBuyVoucher = (
  dealID,
  dealTitle,
  merchantName,
  priceAbc,
  redeem,
  balance,
) => {
  mixpanel.people.set_once(
    "First Buy Voucher Date",
    formatDateTime(redeem.redeemDetails.issuedAt, "DD MMM YYYY HH:mm"),
  );
  mixpanel.people.increment("# of Voucher Purchases", 1);
  mixpanel.people.set({
    "ABC Balance": balance - priceAbc,
    "Last Buy Voucher Date": formatDateTime(
      redeem.redeemDetails.issuedAt,
      "DD MMM YYYY HH:mm",
    ),
  });
  mixpanel.track("Buy Voucher", {
    "Merchant Name": merchantName,
    "Deal ID": dealID,
    "Deal Name": dealTitle,
    "Deal Price - ABC": priceAbc,
    "Buy Voucher Txn ID": redeem.redeemDetails.reference,
  });
};

export const trackLinkTrueId = trueId => {
  const linkedAccount = mixpanel.get_property("Linked Account");
  let newLinkedAccount = linkedAccount ? linkedAccount : [];
  if (!newLinkedAccount.includes("True ID")) {
    newLinkedAccount.push("True ID");
  }
  const trueIdHash = new jshashes.SHA256().hex(trueId);
  mixpanel.register({
    "Linked Account": newLinkedAccount,
  });
  mixpanel.people.set({
    "Linked Account": newLinkedAccount,
    "True ID": trueIdHash,
  });
  mixpanel.track("Link True ID", {
    "True ID": trueIdHash,
  });
};

export const trackConvertCoinsComplete = (
  partnerName,
  spendPoint,
  earnCoin,
  exchangeRate,
  accountId,
  abcBalance,
) => {
  const accountIdHash = new jshashes.SHA256().hex(accountId);
  mixpanel.people.increment("# of Buy Coin with Point", 1);
  mixpanel.people.set_once({
    "First Buy Coin with Point Date": new Date().toISOString(),
  });
  mixpanel.people.set({
    "Last Buy Coin with Point Date": new Date().toISOString(),
    "ABC Balance": abcBalance,
  });
  mixpanel.track("Buy ABC Coin with Point", {
    "Currency of the point": partnerName,
    "Spend Point": spendPoint,
    "Earn Coin": earnCoin,
    "P2C Exchange Rate": exchangeRate,
    "3rd Party Account ID": accountIdHash,
    "ABC Balance": abcBalance,
  });
};

export const trackVisitTransferCoindSendPage = () => {
  mixpanel.track('P2P Send Page')
}

export const trackVisitTransferCoindReviewPage = () => {
  mixpanel.track('P2P Review Page')
}

export const trackTransferCoinComplete = (
  sendAmount,
  sendTo,
  transactionId
) => {
  mixpanel.people.increment("# of P2P", 1);
  mixpanel.people.set_once({
    "First P2P Date": new Date().toISOString(),
  });
  mixpanel.people.set({
    "Last P2P Date": new Date().toISOString()
  });
  mixpanel.track("P2P Success", {
    "Send Amount": sendAmount,
    "Send To": sendTo,
    "P2P Txn ID": transactionId,
  });
}

export const trackVisitMarketplacePage = () => {
  mixpanel.track("Visit Marketplace Page");
};

export const trackViewVoucher = (
  merchantName,
  merchantCategory,
  dealId,
  dealName,
  dealPrice,
) => {
  mixpanel.track("View Voucher", {
    "Merchant Name": merchantName,
    "Merchant Category": merchantCategory,
    "Deal ID": dealId,
    "Deal Name": dealName,
    "Deal Price - ABC": dealPrice,
  });
};

export const trackVisitMyWalletPage = abcBalance => {
  mixpanel.people.set({
    "ABC Balance": abcBalance,
  });
  mixpanel.track("Visit My Wallet Page", {
    "ABC Balance": abcBalance,
  });
};

//********************TMN********************

export const trackTMNSignup = (user, isSignup = false) => {
  mixpanel.TMN.identify(user.tmwID);
  isSignup && mixpanel.TMN.track("ABC Wallet - Register Success");
};

export const trackTMNVisitHomePage = coins => {
  mixpanel.TMN.people.set({
    "Last View ABC Wallet": new Date().toISOString(),
    "Last ABC Wallet Balance": coins.balance,
  });
  mixpanel.TMN.track("ABC Wallet - Home Landing", {
    "ABC Balance": coins.balance,
  });
};

export const trackTMNRedeemCoupon = (dealTitle, merchantName, priceAbc) => {
  mixpanel.TMN.track("ABC Wallet - Redeem Coupon", {
    "Merchant Name": merchantName,
    "Coupon Name": dealTitle,
    Amount: priceAbc,
  });
};

export const QUICK_ACTION_TYPE = {
  "Buy Voucher": "Buy Voucher", 
  P2P: "P2P", 
  P2C: "P2C", 
  NFT: "NFT"
}

export const trackHomeQuickAction = (quickActionType) => {
  mixpanel.track("Click Quick Action", {
    "Quick Action": quickActionType
  });
}
