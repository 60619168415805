import React, { useEffect } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import CheckIcon from "../../../../assets/images/MyWallet/Check_Base.png";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import NftThumbnail from "../../components/nftThumbnail/NftThumbnail";
import { transactionFormatter } from "../../../../utils/transactionFormatter";

const ConvertCompleteLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
`;

const DetailLine = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const DetailDivinder = styled(Divider)`
  width: 100%;
  border-color: #e1e1e1;
`;

const SendNftComplete = () => {
  const transaction = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();

  useEffect(() => {
    setActiveMenu(false);
  }, [setActiveMenu]);

  const handleDoneClick = () => {
    navigate("/wallet/nfts");
  };

  return (
    <>
      <Helmet>
        <title>{t("Complete")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px",
          height: "calc(100vh - 50px)",
        }}
      >
        <ConvertCompleteLayout>
          <Box component="img" src={CheckIcon} />
          <Typography id="successTitle" variant="subtitle">
            {t("Sent successfully")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "120px",
                mb: "16px",
              }}
            >
              <NftThumbnail token={transaction?.token} />
            </Box>
            <Typography variant="subtitle">{transaction?.tokenName}</Typography>
          </Box>
          <DetailDivinder />
          <DetailLine>
            <Typography variant="body2" sx={{ color: "grey.accent2" }}>
              {t("Date & Time")}
            </Typography>
            <Typography variant="body2">
              {formatDateTime(transaction?.dateTime, "DD MMM YYYY, HH:mm")}
            </Typography>
          </DetailLine>
          <DetailLine>
            <Typography variant="body2" sx={{ color: "grey.accent2" }}>
              {t("Transaction ID.")}
            </Typography>
            <Typography variant="body2" style={{ maxWidth: "120px" }}>
              {transactionFormatter(transaction?.transactionId)}
            </Typography>
          </DetailLine>
          <DetailLine>
            <Typography variant="body2" sx={{ color: "grey.accent2" }}>
              {t("From")}
            </Typography>
            <Box sx={{ textAlign: "right" }}>
              <Typography variant="body2">{transaction?.fromName}</Typography>
              <Typography variant="body2">
                {transaction?.fromMobileNumber}
              </Typography>
            </Box>
          </DetailLine>
          <DetailLine>
            <Typography variant="body2" sx={{ color: "grey.accent2" }}>
              {t("To")}
            </Typography>
            <Box sx={{ textAlign: "right" }}>
              <Typography variant="body2">{transaction?.toName}</Typography>
              <Typography variant="body2">
                {transaction?.toMobileNumber}
              </Typography>
            </Box>
          </DetailLine>
        </ConvertCompleteLayout>
        <Button
          id="done-btn"
          data-testid="done-btn"
          variant="contained"
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          fullWidth
          onClick={handleDoneClick}
        >
          {t("DONE")}
        </Button>
      </Box>
    </>
  );
};

export default SendNftComplete;
