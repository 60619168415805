import axios from "axios";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const consentAPI = axios.create({
  baseURL: apiEndpoint.tos,
  headers: {
    "content-type": "application/json",
  },
});

consentAPI.interceptors.request.use(refreshInterceptor);

export default consentAPI;
