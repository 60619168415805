import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import mockApis from "../__fakeAPI__/customerApi";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const RateAPI = axios.create({
  baseURL: apiEndpoint.rate,
  headers: {
    "content-type": "application/json",
  },
});

RateAPI.interceptors.request.use(refreshInterceptor);

export default RateAPI;
