import { useEffect } from "react";
import { useDispatch, useSelector } from "../../../redux/store/configureStore";
import {
  fetchAsyncNfts,
  resetNfts,
  setOwnerAddress as setOwnerAddressRedux,
  removeOwnerAddress as removeOwnerAddressRedux,
  resetError as resetErrorRedux,
} from "../redux/slices/nfts/Nfts";
import { status as nftsStatus } from "../redux/slices/nfts/Nfts";

const useNfts = () => {
  const dispatch = useDispatch();
  const { nfts, ownerAddress, status, cursor, hasMore } = useSelector(
    (state) => state.nfts
  );
  const limit = 10;

  useEffect(() => {
    const storedEthAddress = localStorage.getItem("ethAddress");
    if (storedEthAddress && !ownerAddress) {
      dispatch(setOwnerAddressRedux(storedEthAddress));
    }
    if (ownerAddress) {
      dispatch(
        fetchAsyncNfts({
          ownerAddress,
          cursor,
          limit,
        })
      );
      return;
    }
  }, [ownerAddress]);

  useEffect(
    () => () => {
      dispatch(resetNfts());
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (status === nftsStatus.ERROR) {
      localStorage.removeItem("ethAddress");
    }
  }, [status]);

  const connect = (ownerAddress) => {
    localStorage.setItem("ethAddress", ownerAddress);
    if (status !== nftsStatus.LOADING) {
      dispatch(setOwnerAddressRedux(ownerAddress));
    }
  };

  const removeOwnerAddress = () => {
    localStorage.removeItem("ethAddress");
    if (status !== nftsStatus.LOADING) {
      dispatch(removeOwnerAddressRedux());
    }
  };

  const resetError = () => {
    if (status !== nftsStatus.LOADING) {
      dispatch(resetErrorRedux());
      removeOwnerAddress();
    }
  };

  const handleNextPage = () => {
    const storedEthAddress = localStorage.getItem("ethAddress");
    dispatch(
      fetchAsyncNfts({
        ownerAddress: ownerAddress || storedEthAddress,
        cursor,
        limit,
      })
    );
  };

  return {
    nfts,
    status,
    ownerAddress,
    hasMore,
    connect,
    handleNextPage,
    removeOwnerAddress,
    resetError,
  };
};

export default useNfts;
