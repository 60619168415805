import React from "react";
import {
  Box,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  ListItemButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useTranslation from "../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import useAuth from "../../hooks/useAuth";

import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-tnc.svg";
import { ReactComponent as PrivacyIcon } from "../../assets/icons/settings-privacy.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/settings-help.svg";
import { ReactComponent as ContactUsIcon } from "../../assets/icons/settings-contact-us.svg";
import { ReactComponent as PrivacySettingIcon } from "../../assets/icons/privacy-setting.svg";
import { useNavigate } from "react-router-dom";
import ProfileSection from "./profileSection/ProfileSection";
import { CollectionPoint } from "../../constants/consent";

const Settings = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation("translation", { keyPrefix: "menu" });

  const settingMenuItems = [
    {
      id: "privacy-settings",
      icon: <PrivacySettingIcon />,
      onClick: () =>
        navigate("/settings/privacy-settings", {
          state: { from: CollectionPoint.PRIVACY_SETTING },
        }),
      label: t("Privacy settings"),
    },
  ];

  const menuItems = [
    {
      id: "settings-terms",
      icon: <SettingsIcon />,
      onClick: () => navigate("/settings/terms-and-conditions"),
      label: t("Terms of service"),
    },
    {
      id: "settings-privacy",
      icon: <PrivacyIcon />,
      onClick: () => navigate("/settings/privacy-policy"),
      label: t("Privacy notice"),
    },
    {
      id: "settings-help",
      icon: <HelpIcon />,
      onClick: () =>
        window.open(
          "https://ascendbit.atlassian.net/servicedesk/customer/portal/7/topic/7c4d2658-527b-440f-ae3d-e29eaa218901",
          "_blank",
          "width=400,height=600",
        ),
      label: t("Help"),
    },
    {
      id: "settings-contact",
      icon: <ContactUsIcon />,
      onClick: null,
      label: t("Contact Us"),
      listItemButtonProps: {
        component: "a",
        target: "_blank",
        href: "mailto:wallet.support@ascendbit.net",
      },
    },
  ];

  const renderMenuListItem = (item, isLast) => {
    return (
      <Box key={item.id}>
        <ListItemButton
          id={item.id}
          TouchRippleProps={{ sx: { color: "primary.main" } }}
          sx={{ p: "16px 15px" }}
          onClick={item.onClick}
          {...item.listItemButtonProps}
        >
          <ListItemIcon sx={{ mr: "11px" }}>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              variant: "body2",
              color: "grey.accent1",
            }}
            sx={{}}
          />

          <ListItemIcon sx={{ m: "0px" }}>
            <ArrowForwardIosIcon
              sx={{
                height: "20px",
                width: "20px",
                color: "grey.accent1",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
        {!isLast ? <Divider /> : null}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Menu")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 120px)",
        }}
      >
        <ProfileSection user={user} />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            backgroundColor: "grey.accent4",
            p: "32px 16px",
          }}
        >
          <Box sx={{ mb: "33px" }}>
            <Typography variant="h6" color="grey.accent1">
              {t("Setting")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "8px",
                backgroundColor: "background.paper",
                p: "0px",
                borderRadius: "16px",
                border: "1px solid",
                borderColor: "grey.accent3",
                overflow: "hidden",
              }}
            >
              <List sx={{ width: "100%", p: "0px" }}>
                {settingMenuItems.map((item, index) =>
                  renderMenuListItem(item, index >= menuItems.length - 1),
                )}
              </List>
            </Box>
          </Box>
          <Typography variant="h6" color="grey.accent1">
            {t("Help & Policies")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: "8px",
              backgroundColor: "background.paper",
              p: "0px",
              borderRadius: "16px",
              border: "1px solid",
              borderColor: "grey.accent3",
              overflow: "hidden",
            }}
          >
            <List sx={{ width: "100%", p: "0px" }}>
              {menuItems.map((item, index) =>
                renderMenuListItem(item, index >= menuItems.length - 1),
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
