import React from "react";

import { Box, Avatar, Skeleton } from "@mui/material";

const SkeletonCard = () => (
  <Box
    sx={{
      borderRadius: "16px",
      boxShadow: 5,
      backgroundColor: "background.paper",
      mb: "16px",
      mx: "1px",
      p: "8px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          width: "92px",
          height: "92px",
          borderRadius: "16px",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          ml: "8px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              mr: "4px",
            }}
          >
            <Avatar
              sx={{
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            >
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
            </Avatar>
          </Box>
          <Skeleton animation="wave" width="100%" height="40px" />
        </Box>
        <Skeleton animation="wave" width="100%" height="40px" />
      </Box>
    </Box>
  </Box>
);

const VouchersSkeleton = () => {
  return (
    <>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </>
  );
};

export default VouchersSkeleton;
