import React from "react";
import { Button, Box, Typography, Avatar } from "@mui/material";
import { ReactComponent as SendIcon } from "../../../../../assets/icons/transaction-send.svg";
import { ReactComponent as ReceiveIcon } from "../../../../../assets/icons/transaction-receive.svg";
import { ReactComponent as RedeemIcon } from "../../../../../assets/icons/transaction-redeem.svg";
import { ReactComponent as ConvertIcon } from "../../../../../assets/icons/transaction-convert.svg";
import { formatDateTime } from "../../../../../utils/dateTimeFormatter";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../../hooks/useTranslation";
import { coinFormatter } from "../../../../../utils/coinFormatter";

export const KIND_TYPE = {
  voucher: 'voucher',
  airdrop: 'airdrop',
  swap: 'swap',
  send: 'send',
  receive: 'receive',
}
export const CURRENCY = "ABC Coin"

export const getIcon = (kind, from, coinAccount) => {
  if (kind === KIND_TYPE.voucher) {
    return <RedeemIcon />
  }
  if (kind === KIND_TYPE.p2p) {
    // check if customer are receiver or sender 
    if (isSender(from, coinAccount)) {
      return <SendIcon />;
    } else {
      return <ReceiveIcon />;
    }
  }
  if (kind === KIND_TYPE.airdrop) {
    return <ReceiveIcon />;
  }
  if (kind === KIND_TYPE.exchange) {
    return <ConvertIcon />
  }
  return ''
}

export const getSymbol = (kind, from, coinAccount) => {
  if (kind === KIND_TYPE.voucher) {
    return '-'
  }
  if (kind === KIND_TYPE.p2p) {
    // check if customer are receiver or sender 
    if (isSender(from, coinAccount)) {
      return '-'
    } else {
      return '+'
    }
  }
  if (kind === KIND_TYPE.airdrop) {
    return '+'
  }
  if (kind === KIND_TYPE.exchange) {
    return '+'
  }
  return ''
}

const isSender = (from, coinAccount) => {
  return from === coinAccount
}

export const getTransactionDetail = (t, lang, kind, currency = CURRENCY) => {
  if (kind === KIND_TYPE.voucher) {
    return {
      title: `${t('Redeem')} ${currency}`, 
      symbol: '-', 
      icon: <ReceiveIcon />
    }
  }
  if (kind === KIND_TYPE.airdrop) {
    return {
      title: lang === 'th' 
        ? `${t('Receive')} ${t("Airdrop")} ${currency}`
        : `${t('Receive')} ${currency} ${t("Airdrop")}`,
      symbol: '+',
      icon: <ReceiveIcon />
    }
  }
  if (kind === KIND_TYPE.swap) {
    return {
      title: `${t("Convert loyalty point to")} ${currency}`,
      symbol: '+',
      icon: <ConvertIcon />
    }
  }
  if(kind === KIND_TYPE.send) {
    return {
      title: `${t("Send")} ${currency}`,
      symbol: '-',
      icon: <SendIcon />
    }
  }
  if(kind === KIND_TYPE.receive) {
    return {
      title: `${t("Receive")} ${currency}`,
      symbol: '+',
      icon: <ReceiveIcon />
    }
  }
  return {
    title: '',
    icon: null,
    symbol: ''
  }
}

const TransactionHistoryCard = ({ data, coinAccount }) => {
  /**
   * Detail: https://ascendbit.atlassian.net/wiki/spaces/ABC/pages/2106196153/Copywriting+Wallet+-+Info#Transaction-type
   * kind: voucher, p2p, airdrop,exchange
   * type: Recive, Redeem, Buy, Convert loyalty point to, Send
   * 
   * Redeem currency name  -> "voucher"
   * Receive currency name Airdrop ->"airdrop"
   * Convert loyalty point to currency name   ->"swap"
   * Send currency name ->"send"
   * Receive currency name ->"receive"
   * */
  
  const { t } = useTranslation("translation", { keyPrefix: "walletHistory" });
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang")

  const handleClick = (transactionHash) => {
    navigate(`/wallet/transaction/${transactionHash}`, {
      state: {
        ...data,
        coinAccount,
        currency: CURRENCY,
      },
    });
  };

  const {
    transactionHash,
    profileID,
    from,
    to,
    amount,
    kind,
    metadata,
    createdAt,
    confirmedAt
  } = data

  const {title, symbol, icon} = getTransactionDetail(t, lang, kind, CURRENCY)

  return (
    <Button
      id="transaction-history-card"
      onClick={() => handleClick(transactionHash)}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        padding: "16px",
        borderRadius: "16px",
        mb: "16px",
        border: "1px solid",
        borderColor: "grey.accent3",
        backgroundColor: "background.paper",
      }}
    >
      <Avatar
        sx={{
          bgcolor: "grey.accent4",
          border: "1px solid",
          borderColor: "grey.accent3",
          marginRight: "8px",
          width: "40px",
          height: "40px",
        }}
      >
        {
          metadata?.merchant?.logo 
          ? (
              <Box
                component="img"
                src={metadata.merchant.logo}
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            ) 
          : icon
        }
      </Avatar>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Typography
          variant="body2"
          sx={{ textAlign: "left", color: "grey.accent1" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ color: "grey.accent2" }}>
            {formatDateTime(confirmedAt, "HH:mm")}
          </Typography>
          <Typography
            variant="subtitle"
            sx={{ textAlign: "right", color: "grey.accent1" }}
          >
            {`${symbol}${coinFormatter(amount + "")} ${CURRENCY}`}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default TransactionHistoryCard;
