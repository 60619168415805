import React from "react";
import Grid from "@mui/material/Grid";
import Nftcard from "../nftCard/NftCard";

const NftList = ({ nfts, selectNft }) => {
  return (
    <Grid container spacing={2} sx={{ px: "20px" }}>
      {nfts
        .filter((nft) => nft.nftType === "Image")
        .map((nft) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              xl={1}
              sx={{ display: "flex" }}
              key={nft.tokenId}
            >
              <Nftcard nft={nft} selectNft={selectNft} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default NftList;
