import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NftsAPI from "../../../../../apiConfig/NftsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  cursor: "",
  nfts: [],
  ownerAddress: null,
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks

export const fetchAsyncNfts = createAsyncThunk(
  "nfts/fetchAsyncNfts",
  async ({ ownerAddress, cursor, limit }, { rejectWithValue }) => {
    try {
      const response = await NftsAPI.get(
        `/nfts/${ownerAddress}?cursor=${cursor}&limit=${limit}`
      );
      return {
        ownerAddress,
        status: response.status,
        data: response.data.data,
        size: response.data.size,
        page: response.data.page,
        pageSize: response.data.pageSize,
        cursor: response.data.cursor,
        limit,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  }
);

//Slices
const NftsSlice = createSlice({
  name: "Nfts",
  initialState,
  reducers: {
    resetNfts: (state) => {
      state.cursor = "";
      state.nfts = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
    setOwnerAddress: (state, action) => {
      state.ownerAddress = action.payload;
    },
    removeOwnerAddress: (state) => {
      state.cursor = "";
      state.nfts = [];
      state.ownerAddress = null;
      state.status = status.EMPTY;
      state.errorMessage = null;
      state.hasMore = false;
    },
    resetError: (state) => {
      state.status = status.EMPTY;
      state.errorMessage = null;
      state.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncNfts.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncNfts.fulfilled, (state, { payload }) => {
        if (payload.data.length === 0 && state.nfts.length === 0) {
          state.errorMessage = null;
          state.hasMore = false;
          state.status = status.EMPTY;
          return;
        }
        state.ownerAddress = payload.ownerAddress;
        state.hasMore =
          payload.page + 1 < Math.ceil(payload.size / payload.pageSize);
        state.nfts = [...state.nfts, ...payload.data];
        state.cursor = payload.cursor;
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncNfts.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetNfts, setOwnerAddress, removeOwnerAddress, resetError } =
  NftsSlice.actions;

export default NftsSlice.reducer;
