import React from "react";
import { Box, Modal, Typography, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import useTranslation from "../../../../../hooks/useTranslation";

const NftErrorModal = ({ isShown, onClose }) => {
  const { t } = useTranslation("translation", { keyPrefix: "nft" });
  return (
    <Modal
      open={isShown}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          width: "calc(100vw - 20px)",
          height: "130px",
          p: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          <IconButton
            id="nft-error-close"
            TouchRippleProps={{ sx: { color: "primary.main" } }}
            onClick={onClose}
          >
            <CloseIcon height={12} width={12} />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "grey.accent1",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            {t("Error")}
            <br /> {t("Unsuccessful Connection")}
          </Typography>
        </Box>

        <Box sx={{ flex: 1 }}></Box>
      </Box>
    </Modal>
  );
};

export default NftErrorModal;
