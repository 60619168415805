import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";

const GuestLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  width: "100%",
}));

const GuestLayoutContent = styled("div")({
  flex: "1 1 auto",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const GuestLayout = () => {
  return (
    <GuestLayoutRoot>
      <GuestLayoutContent>
        <Outlet />
      </GuestLayoutContent>
    </GuestLayoutRoot>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.node,
};

export default GuestLayout;
