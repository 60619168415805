import React from "react";
import { styled } from "@mui/system";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import useTranslation from "../../../../hooks/useTranslation";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#5185EC",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "1px solid #5185EC",
    },
    input: {
      padding: "10px 12px",
      "caret-color": "#5185EC",
    },
  },
  marginBottom: 20,
});

export default function DealsSearchBar({
  handleChange,
  value,
  handleDelete,
  handleSearch,
}) {
  const { t } = useTranslation("translation", { keyPrefix: "searchVoucher" });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <CssTextField
      id="search-field"
      value={value}
      onChange={handleChange}
      placeholder={t("Search")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton sx={{ m: 0, p: 0 }} onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton sx={{ m: 0, p: 0 }} onClick={handleDelete}>
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </InputAdornment>
        ),
      }}
      autoComplete="off"
      type="text"
      onKeyPress={handleKeyPress}
    />
  );
}
