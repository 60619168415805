import React, { createContext } from "react";

const LoadingScreenContext = createContext();

export const LoadingScreenProvider = ({ children, value }) => {
  return (
    <LoadingScreenContext.Provider value={value}>
      {children}
    </LoadingScreenContext.Provider>
  );
};

export default LoadingScreenContext;
