import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import NftTab from "../../components/nftTab/NftTab";
import { featureFlag } from "../../../../config";

const Nft = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "walletVoucherTab",
  });
  const setActiveMenu = useNavigationHook();
  const [activeTab, setActiveTab] = useState(
    window.location.pathname === "/wallet/nfts" ? 0 : 1,
  );

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  return (
    <>
      <Helmet>
        <title>{t("My Wallet")}</title>
      </Helmet>
      <Box sx={{ mt: "4px" }}>
        {featureFlag.enableNftMyCollection && (
          <NftTab onChange={setActiveTab} activeTab={activeTab} />
        )}
        <Outlet />
      </Box>
    </>
  );
};

export default Nft;
