import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom';
import useNavigationHook from '../../../../hooks/useNavigation';
import useTranslation from '../../../../hooks/useTranslation';
import { useDispatch } from '../../../../redux/store/configureStore';
import { ConvertCompleteLayout, DetailDivinder, DetailLine } from '../convertCoinsComplete/ConvertCoinsComplete';
import CheckIcon from '../../../../assets/images/MyWallet/Check_Base.png'
import Coins from '../../../../assets/icons/coins.svg'
import { formatDateTime } from '../../../../utils/dateTimeFormatter';
import { resetTransferCoin } from '../../redux/slices/transferCoin/TransferCoin';
import { resetUserProfile } from '../../redux/slices/userProfile/userProfile';
import { trackTransferCoinComplete } from '../../../../mixpanel';

const TransferCoinComplete = () => {
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const transfer = useLocation().state?.transferComplete
  const {
    amount,
    date,
    transactionId,
    fromWalletAddress,
    toHash,
    to
  } = transfer
  const { t } = useTranslation("translation", {
    keyPrefix: "transferCoin",
  });

  useEffect(() => {
    if (amount && toHash && transactionId) {
      trackTransferCoinComplete(
        amount,
        toHash,
        transactionId
      )
    }
  }, [amount, toHash, transactionId])

  useEffect(() => {
    setActiveMenu(false)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetTransferCoin())
      dispatch(resetUserProfile())
    }
  }, [])

  const handleDoneClick = () => {
    navigate('/wallet')
  }

  return (
    <>
      <Helmet>
        <title>
          {t("Complete")}
        </title>
      </Helmet>
      <Box
        sx={{
          padding: '32px 16px'
        }}
      >
        <ConvertCompleteLayout>
          <Box
            component='img'
            src={CheckIcon} />
          <Typography
            id="successTitle"
            variant='subtitle'
            style={{ fontWeight: 500 }}>
            {t("Sent successfully")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              alt="Coins"
              component="img"
              src={Coins}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            />
            <Typography id="amountState" variant='h6'>
              {amount} ABC
            </Typography>
          </Box>
          <DetailDivinder />
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('Date')}
            </Typography>
            <Typography variant='body2'>
              { formatDateTime(date, 'DD MMM YYYY, HH:mm') }
            </Typography>
          </DetailLine>
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('Transaction ID')}
            </Typography>
            <Typography 
              variant='body2'
              sx={{
                width: '50%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              >
              { transactionId }
            </Typography>
          </DetailLine>
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{
                color: 'grey.accent2',
                
              }}>
              {t('From')}
            </Typography>
            <Typography 
              variant='body2'
              sx={{
                width: '50%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
             >
              { fromWalletAddress }
            </Typography>
          </DetailLine>
          <DetailLine>
            <Typography 
              variant='body2' 
              sx={{color: 'grey.accent2'}}>
              {t('To')}
            </Typography>
            <Box>
              <Typography variant='body2'>
                { to.userName }
              </Typography>
              <Typography variant='body2'>
                { to.phoneNumber }
              </Typography>
            </Box>
            
          </DetailLine>
        </ConvertCompleteLayout>
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          p: "16px 16px 32px",
          backgroundColor: "paper.white",
        }}
      >
        <Button
          data-testid='transfer_coin.done_button'
          color="primary"
          to="/transfer-review"
          variant="contained"
          sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
          fullWidth
          onClick={handleDoneClick}
        >
          <Typography variant="button">{t("Done")}</Typography>
        </Button>
      </Box>
    </>
  )
}

export default TransferCoinComplete