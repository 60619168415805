import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DealsAPI from "../../../../../apiConfig/DealsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  page: 1,
  deals: [],
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks
export const fetchAsyncDeals = createAsyncThunk(
  "deals/fetchAsyncDeals",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      let title;
      if (localStorage.getItem("lang") === "th") title = "titleTh";
      else title = "titleEn";

      const response = await DealsAPI.get(
        `/deals?page=${page}&limit=${limit}&sortBy=${title}`
      );
      return {
        status: response.status,
        data: response.data.entries,
        totalPages: response.data.totalPages,
        limit: limit,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  }
);

//Slices
const DealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    nextPage: (state, action) => {
      state.page = state.page + 1;
    },
    resetDeals: (state, action) => {
      state.page = 1;
      state.deals = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncDeals.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncDeals.fulfilled, (state, { payload }) => {
        if (!payload.data || payload.data.length === 0) {
          state.errorMessage = null;
          state.hasMore = false;
          state.status = status.EMPTY;
          return;
        }

        state.hasMore = state.page < payload.totalPages;
        state.deals = [...state.deals, ...payload.data];
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncDeals.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});
export const { nextPage, resetDeals } = DealsSlice.actions;

export default DealsSlice.reducer;
