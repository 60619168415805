import { combineReducers } from "redux";
import termsReducers from "../slices/terms/Terms";
import ConsentReducers from "../slices/consent/Consent";
import SaveConsentReducers from "../slices/consent/SaveConsent";
import dealsReducers from "../../features/deals/redux/slices/deals/Deals";
import merchantsReducers from "../../features/deals/redux/slices/deals/Merchants";
import dealsSearchReducers from "../../features/deals/redux/slices/deals/DealsSearch";
import MerchantDealsReducers from "../../features/deals/redux/slices/deals/MerchantDeals";
import CoinsReducers from "../../features/wallet/redux/slices/coins/Coins";
import TransactionHistoryReducers from "../../features/wallet/redux/slices/transactionHistory/TransactionHistory";
import vouchersReducers from "../../features/wallet/redux/slices/vouchers/vouchers";
import redeemReducers from "../../features/deals/redux/slices/deals/Redeem";
import PartnerRatesReducers from "../../features/wallet/redux/slices/partnerRates/PartnerRates";
import nftsReducers from "../../features/wallet/redux/slices/nfts/Nfts";
import convertPointsReducers from "../../features/wallet/redux/slices/convertPoints/ConvertPoints";
import truePointsBalanceReducers from "../../features/wallet/redux/slices/truePointsBalance/TruePointsBalance";
import nftMyCollectionReducers from "../../features/wallet/redux/slices/nftMyCollection/NftMyCollection";
import nftMyTokenReducers from "../../features/wallet/redux/slices/nftMyCollection/NftMyToken";
import userProfileReducers from "../../features/wallet/redux/slices/userProfile/userProfile";
import nftCheckMobileNumberReducers from "../../features/wallet/redux/slices/nftCheckMobileNumber/NftCheckMobileNumber";
import nftMyCollectionSendnReducers from "../../features/wallet/redux/slices/nftMyCollectionSend/NftMyCollectionSend";
import transferCoinReducers from '../../features/wallet/redux/slices/transferCoin/TransferCoin'
import trueIdLoginReducers from '../../features/wallet/redux/slices/trueIdLogin/trueIdLogin'

const rootReducer = () =>
  combineReducers({
    terms: termsReducers,
    consent: ConsentReducers,
    saveConsent: SaveConsentReducers,
    deals: dealsReducers,
    merchants: merchantsReducers,
    dealsSearch: dealsSearchReducers,
    merchantDeals: MerchantDealsReducers,
    coins: CoinsReducers,
    transactionHistory: TransactionHistoryReducers,
    vouchers: vouchersReducers,
    redeem: redeemReducers,
    rates: PartnerRatesReducers,
    nfts: nftsReducers,
    convertPoints: convertPointsReducers,
    truePointsBalance: truePointsBalanceReducers,
    nftMyCollection: nftMyCollectionReducers,
    nftMyToken: nftMyTokenReducers,
    userProfile: userProfileReducers,
    nftCheckMobileNumber: nftCheckMobileNumberReducers,
    nftMyCollectionSend: nftMyCollectionSendnReducers,
    transferCoin: transferCoinReducers,
    trueIdLogin: trueIdLoginReducers
  });

export default rootReducer;
