import React from "react";
import { Box } from "@mui/material";
import GenericErrorImg from "../../../../assets/images/GenericErrorImg.svg";

const NftAsset = ({ token }) => {
  return (
    <>
      {token?.metadata?.animation_url ? (
        <video
          width="100%"
          autoPlay
          loop
          controls
          controlsList="nodownload"
          playsInline
        >
          <source
            src={token?.metadata?.animation_url}
            type="video/mp4"
            data-testid="nft-video-source"
          />
          Your mobile does not support the video tag.
        </video>
      ) : (
        <Box
          alt="NFT image"
          component="img"
          src={token?.metadata?.image}
          sx={{
            width: "100%",
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
            mb: "24px",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = GenericErrorImg;
          }}
        />
      )}
    </>
  );
};

export default NftAsset;
