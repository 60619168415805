import React from "react";
import { Box, Typography } from "@mui/material";
import useTranslation from "../../../../hooks/useTranslation";

const VoucherError = () => {
  const { t } = useTranslation("translation", { keyPrefix: "vouchers" });

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "grey.accent3",
        borderRadius: "16px",
        minHeight: "292px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.paper",
      }}
    >
      <Typography
        sx={{
          m: "122px 47px",
        }}
        color="grey.accent2"
        variant="body2"
        align="center"
      >
        {t("This function is temporarily unavailable. Please try again later")}
      </Typography>
    </Box>
  );
};

export default VoucherError;
