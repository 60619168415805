import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import Pilot1 from "../../../../assets/images/Marketplace/banner/KV_ABC_Top banner_1440x575pix_V3-01-20220607-115411.jpg";
import Pilot2 from "../../../../assets/images/Marketplace/banner/KV_ABC_Top banner_1440x575pix_V3-02-20220607-115220.jpg";
import { paletteLight } from "../../../../theme";

const CarouselBanner = () => {
  var items = [
    {
      id: "Pilot1",
      src: Pilot1,
    },
    {
      id: "Pilot2",
      src: Pilot2,
    },
  ];

  return (
    <Carousel
      sx={{
        height: "154px",
      }}
      navButtonsAlwaysInvisible={true}
      indicators={true}
      stopAutoPlayOnHover={false}
      animation="slide"
      interval={5000}
      duration={1000}
      indicatorContainerProps={{
        style: {
          position: "absolute",
          bottom: "8px",
          zIndex: "1",
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: paletteLight.background.red,
        },
      }}
      indicatorIconButtonProps={{
        style: {
          color: paletteLight.grey.accent3,
        },
      }}
    >
      {items.map((item, i) => (
        <Box
          component="img"
          key={i}
          src={item.src}
          sx={{
            height: { xs: "154px", sm: "308px" },
            padding: "8px",
            width: "100%",
            objectFit: "cover",
            borderRadius: "16px",
            display: "flex",
          }}
        />
      ))}
    </Carousel>
  );
};

export default CarouselBanner;
