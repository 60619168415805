import axios from "axios";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const TransferCoinAPI = axios.create({
  baseURL: apiEndpoint.transferCoin,
  headers: {
    "content-type": "application/json",
  },
});

TransferCoinAPI.interceptors.request.use(refreshInterceptor);

export default TransferCoinAPI;
