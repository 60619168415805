import React from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  Avatar,
} from "@mui/material";

import Check from "../../../../assets/icons/redemptionSuccessCheck.svg";
import Coins from "../../../../assets/icons/coins.svg";
import Logo from "../../../../assets/icons/company-logos/true-icon.svg";

import useTranslation from "../../../../hooks/useTranslation";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { twoDecimalCoin } from "../../../../utils/coinFormatter";
import { trackTMNRedeemCoupon, trackBuyVoucher } from "../../../../mixpanel";

const SuccessRedemption = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "successRedemption",
  });
  const navigate = useNavigate();
  const [quantity] = React.useState(1);
  const redeem = useLocation().state.redeem;
  const { reference, issuedAt, deal } = redeem.redeemDetails;
  const { id, title, tagline, priceAbc, merchant } = deal;
  const { balance } = useLocation().state.coins;

  const labels = [
    {
      label: t("Date & Time"),
      data: formatDateTime(issuedAt, "DD MMM YYYY HH:mm"),
    },
    {
      label: t("Reference ID"),
      data: reference,
    },
    {
      label: t("Quantity"),
      data: quantity,
    },
    {
      label: t("Redemption Method"),
      data: quantity,
    },
  ];

  React.useEffect(() => {
    trackBuyVoucher(id, title, merchant.name, priceAbc, redeem, balance);
    trackTMNRedeemCoupon(title, merchant.name, priceAbc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Redemption Successful")}</title>
      </Helmet>
      <Box
        sx={{
          pt: "32px",
          height: 'calc(100vh - 133px - 50px)',
          overflow: 'scroll'
        }}
      >
        <Box
          sx={{
            border: "1px solid",
            m: "0px 16px 0px 16px",
            backgroundColor: "background.paper",
            borderRadius: "16px",
            borderColor: "grey.accent3",
            p: "16px 0px 24px 0px",
          }}
        >
          <Box
            alignItems="center"
            justifyContent="center"
            sx={{
              m: "12px 0 0px 0",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Avatar
              sx={{
                border: 1,
                bgcolor: "grey.accent4",
                borderColor: "grey.accent3",
                width: "40px",
                height: "40px",
              }}
            >
              <Box
                component="img"
                src={Check}
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            </Avatar>
            <Typography variant="subtitle" sx={{ mt: "12px", mb: "16px" }}>
              {t("Successfully Redeemed")}
            </Typography>
          </Box>
          <Divider variant="middle" sx={{ bgcolor: "grey.accent3" }} />
          <Box
            sx={{
              m: "16px 16px 0 16px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                src={Logo}
              />
              <Typography variant="body2" sx={{ ml: "8px" }}>
                {merchant.name}
              </Typography>
            </Box>
            <Typography variant="h6" sx={{ mt: "8px", mb: "16px" }}>
              {tagline}
            </Typography>
          </Box>
          <Divider
            variant="middle"
            sx={{ bgcolor: "grey.accent3", mb: "32px" }}
          />
          {labels.map(({ label, data }) => {
            return label !== t("Redemption Method") ? (
              <Box
                key={label}
                sx={{
                  m: "16px 16px 0 16px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box>
                  <Typography variant="body2" color="grey.accent2">
                    {label}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{data}</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                key={label}
                sx={{
                  m: "16px 16px 0 16px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" color="grey.accent2">
                  {label}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box
                    alt="Coins"
                    component="img"
                    src={Coins}
                    sx={{
                      objectFit: "cover",
                      height: "24px",
                      width: "24px",
                      mr: "8px",
                    }}
                  />

                  <Typography variant="subtitle" sx={{ ml: "8px" }}>
                    ABC
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Divider
            variant="middle"
            sx={{ bgcolor: "grey.accent3", my: "20px" }}
          />
          <Box
            sx={{
              m: "24px 16px 0 16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="subtitle">
              {t("Grand Total Amount")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box
                alt="Coins"
                component="img"
                src={Coins}
                sx={{
                  objectFit: "cover",
                  height: "24px",
                  width: "24px",
                  mr: "8px",
                }}
              />
              <Typography variant="subtitle" sx={{ ml: "8px" }}>
                {twoDecimalCoin(priceAbc * quantity) + " ABC"}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            p: "16px 0 32px 0",
            px: "16px",
            width: "100%",
            height: '133px',
            position: "fixed",
            bottom: 0,
            backgroundColor: "background.paper",
            borderTop: "1px solid",
            borderColor: "grey.accent3",
          }}
        >
          <Typography variant="body2" textAlign="center" sx={{ mb: "16px" }}>
            {t("View")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              id="marketplace"
              variant="outlined"
              fullWidth
              sx={{ height: 48, mr: "8px", borderRadius: "24px" }}
              onClick={() => navigate("/marketplace")}
            >
              <Typography variant="button">{t("Marketplace")}</Typography>
            </Button>
            <Button
              id="wallet"
              variant="contained"
              fullWidth
              sx={{ height: 48, ml: "8px", borderRadius: "24px" }}
              onClick={() => {
                navigate("/wallet/my-vouchers");
              }}
            >
              <Typography variant="button">{t("My Vouchers")}</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SuccessRedemption;
