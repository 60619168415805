import React, { createContext } from "react";

const NavigationContext = createContext();

export const NavigationProvider = ({ children, value }) => {
  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
