import moment from "moment";
import thLocale from "moment/locale/th";
moment.locale("en");

const dateTimeFormatter = (date) => {
  if (date) {
    const indx = date.indexOf("T");
    const year = date.substr(0, 4);
    const day = date.substr(8, 2);
    const month = date.substr(5, 2);
    const time = date.substr(indx + 1, 5);

    return `${day}/${month}/${year} ${time}`;
  } else return;
};

export const newDateTime = () => {
  const date = new Date();
  return date.toISOString();
};

export const formatDateTime = (date, format) => {
  const lang = localStorage.getItem("lang");
  const momentDate = moment(date);
  if (lang === "th") {
    moment.updateLocale("th", [thLocale]);
    return momentDate.add(543, "years").format(format);
  }
  return momentDate.format(format);
};

export default dateTimeFormatter;
