import merge from "lodash/merge";
import { createTheme } from "@mui/material/styles";
import { THEMES } from "../constants";
import { lightShadows } from "./shadows";

import AnuyartDemibold600eot from "../fonts/Anuyart/600/04anuyart-demibold-webfont.eot";
import AnuyartDemibold600IEFixeot from "../fonts/Anuyart/600/04anuyart-demibold-webfont-iefix.eot";
import AnuyartDemibold600woff2 from "../fonts/Anuyart/600/04anuyart-demibold-webfont.woff2";
import AnuyartDemibold600woff from "../fonts/Anuyart/600/04anuyart-demibold-webfont.woff";
import AnuyartDemibold600ttf from "../fonts/Anuyart/600/04anuyart-demibold-webfont.ttf";

import AnuyartDemibold300eot from "../fonts/Anuyart/300/02anuyart-light-webfont.eot";
import AnuyartDemibold300IEFixeot from "../fonts/Anuyart/300/02anuyart-light-webfont-iefix.eot";
import AnuyartDemibold300woff2 from "../fonts/Anuyart/300/02anuyart-light-webfont.woff2";
import AnuyartDemibold300woff from "../fonts/Anuyart/300/02anuyart-light-webfont.woff";
import AnuyartDemibold300ttf from "../fonts/Anuyart/300/02anuyart-light-webfont.ttf";

import AnuyartDemibold100eot from "../fonts/Anuyart/100/01anuyart-ultralight-webfont.eot";
import AnuyartDemibold100IEFixeot from "../fonts/Anuyart/100/01anuyart-ultralight-webfont-iefix.eot";
import AnuyartDemibold100woff2 from "../fonts/Anuyart/100/01anuyart-ultralight-webfont.woff2";
import AnuyartDemibold100woff from "../fonts/Anuyart/100/01anuyart-ultralight-webfont.woff";
import AnuyartDemibold100ttf from "../fonts/Anuyart/100/01anuyart-ultralight-webfont.ttf";

const baseOptions = language => {
  return {
    direction: "ltr",
    components: {
      MuiAvatar: {
        styleOverrides: {
          fallback: {
            height: "75%",
            width: "75%",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiCssBaseline: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "#nprogress .bar": {
          zIndex: "2000 !important",
        },
        styleOverrides: `
          @font-face {
              font-family: anuyart;
              src: url(${AnuyartDemibold600eot});
              src: url(${AnuyartDemibold600IEFixeot}) format("embedded-opentype"), url(${AnuyartDemibold600woff2}) format("woff2"), url(${AnuyartDemibold600woff}) format("woff"), url(${AnuyartDemibold600ttf}) format("truetype");
              font-weight: 600;
              font-style: normal;
              letter-spacing: 0;
              font-display:swap
          }
          
          @font-face {
              font-family: anuyart;
              src: url(${AnuyartDemibold300eot});
              src: url(${AnuyartDemibold300IEFixeot}) format("embedded-opentype"), url(${AnuyartDemibold300woff2}) format("woff2"), url(${AnuyartDemibold300woff}) format("woff"), url(${AnuyartDemibold300ttf}) format("truetype");
              font-weight: 300;
              font-style: normal;
              letter-spacing: 0;
              font-display:swap
          }
          
          @font-face {
              font-family: anuyart;
              src: url(${AnuyartDemibold100eot});
              src: url(${AnuyartDemibold100IEFixeot}) format("embedded-opentype"), url(${AnuyartDemibold100woff2}) format("woff2"), url(${AnuyartDemibold100woff}) format("woff"), url(${AnuyartDemibold100ttf}) format("truetype");
              font-weight: 100;
              font-style: normal;
              letter-spacing: 0;
              font-display:swap
          }
        `,
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: "h6",
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            overflow: "hidden",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "auto",
            marginRight: "16px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
        },
      },
    },
    typography: {
      fontFamily:
        language === "th"
          ? '"Anuyart", "Noto Serif Thai", "sans-serif"'
          : "Roboto",
      h1: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "6rem",
        letterSpacing: "0px",
      },
      h2: {
        fontWeight: 400,
        fontSize: "4rem",
        letterSpacing: "0px",
      },
      h3: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "3rem",
        letterSpacing: "0px",
      },
      h4: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "2rem",
        letterSpacing: "0px",
      },
      h5: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "1.5rem",
        letterSpacing: "0px",
      },
      h6: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "1.250rem",
        letterSpacing: "0px",
      },
      pharagraph: {
        fontWeight: 400,
        fontSize: "1.125rem",
      },
      subtitle: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "1.000rem",
      },
      subtitle1: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "1.000rem",
      },
      body1: {
        fontWeight: 400,
        fontSize: "1.000rem",
      },
      body2: {
        fontWeight: 400,
        fontSize: "0.875rem",
      },
      lead: {
        fontWeight: 400,
        fontSize: "0.875rem",
      },
      button: {
        fontWeight: language === "th" ? 600 : 500,
        fontSize: "1.000rem",
        letterSpacing: "normal",
        textRendering: "optimizeLegibility",
      },
      helpingText: {
        fontWeight: 400,
        fontSize: "0.750rem",
      },
    },
  };
};

export const paletteLight = {
  action: {
    active: "#6b778c",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
    drawer: "#FBF9FA",
    red: "#E50914",
  },
  error: {
    contrastText: "#ffffff",
    main: "#DD2803",
  },
  mode: "light",
  primary: {
    contrastText: "#FFFFFF",
    main: "#E50914",
    accent1: "#B30C14",
    accent2: "#E50914",
    accent3: "#FF6C57",
    accent4: "#FF9A84",
  },
  secondary: {
    main: "#FDAC49",
    orange: {
      contrastText: "#ffffff",
      main: "#FDAC49",
      accent1: "#FC8E09",
      accent2: "#FDAC49",
      accent3: "#FED194",
      accent4: "#FFEDD7",
    },
    yellow: {
      contrastText: "#ffffff",
      main: "#FFCA4B",
      accent1: "#F4AD01",
      accent2: "#FFCA4B",
      accent3: "#FDDE86",
      accent4: "#FFF0C6",
    },
    pink: {
      contrastText: "#ffffff",
      main: "#FB7D74",
      accent1: "#E14D42",
      accent2: "#FB7D74",
      accent3: "#FDB5AF",
      accent4: "#FEDAD7",
    },
    blue: {
      contrastText: "#ffffff",
      main: "#51A9F6",
      accent1: "#0072D4",
      accent2: "#51A9F6",
      accent3: "#81C3FC",
      accent4: "#D9EDFF",
      accent5: "#1C93E9",
    },
    green: {
      contrastText: "#ffffff",
      main: "#2ACEAA",
      accent1: "#09A381",
      accent2: "#2ACEAA",
      accent3: "#51A9F6",
      accent4: "#DDF8F2",
    },
  },
  success: {
    contrastText: "#ffffff",
    main: "#2ACEAA",
  },
  text: {
    primary: "#000000",
    secondary: "#6b778c",
  },
  informative: {
    contrastText: "#ffffff",
    main: "#118BF3",
  },
  toast: {
    contrastText: "#ffffff",
    main: "#141312",
  },
  warning: {
    contrastText: "#ffffff",
    main: "#FCC644",
  },
  grey: {
    accent1: "#141312",
    accent2: "#8C8C8A",
    accent3: "#E1E1E1",
    accent4: "#F5F5F4",
    accent5: "#FFFFFF",
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&::placeholder": {
              opacity: 0.86,
              color: "#42526e",
            },
          },
        },
      },
    },
    palette: paletteLight,
    shadows: lightShadows,
  },
};

export const CreateCustomTheme = config => {
  let themeOptions = themesOptions[config.theme];
  let language = localStorage.getItem("lang");

  return createTheme(
    merge(
      {},
      baseOptions(language),
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 5,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );
};
