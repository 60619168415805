import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "../../redux/store/configureStore";
import { fetchAsyncTerms } from "../../redux/slices/terms/Terms";
import useTranslation from "../../hooks/useTranslation";
import TermsAndConditionsSkeleton from "../../components/skeletonLoading/TermsAndConditionsSkeleton";
import GenericError from "../genericError/GenericError";
import { Helmet } from "react-helmet-async";
import AuthorizationError from "../authorizationError/AuthorizationError";
import parse from "html-react-parser";
import useNavigationHook from "../../hooks/useNavigation";

const TermsMenu = () => {
  const setActiveMenu = useNavigationHook();
  const dispatch = useDispatch();
  const { isLoading, terms, status } = useSelector((state) => state.terms);
  const { t } = useTranslation("translation", {
    keyPrefix: "termsAndConditions",
  });

  React.useEffect(() => {
    setActiveMenu(false);
    dispatch(fetchAsyncTerms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatContent = (content) => {
    const removeOpeningTag = content.replace("<html>", "");
    const removeClosingTag = removeOpeningTag.replace("</html>", "");
    return parse(removeClosingTag);
  };

  return (
    <>
      <Helmet>
        <title>{t("Terms of Service")}</title>
      </Helmet>
      {isLoading ? (
        <Box sx={{ backgroundColor: "background.paper", padding: "0 16px" }}>
          <TermsAndConditionsSkeleton />
        </Box>
      ) : status === 401 ? (
        <AuthorizationError />
      ) : status === 200 ? (
        terms && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "background.paper",
              padding: "0 16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // textAlign: "justify",
                // textJustify: "inter-word",
                inlineSize: "auto",
                wordBreak: "break-word",
              }}
            >
              <Typography variant="body1">
                {formatContent(terms.data.content)}
              </Typography>
            </Box>
          </Box>
        )
      ) : status === 500 ? (
        <Box sx={{ width: "100%" }}>
          <GenericError
            title="Service is not available"
            body="Service is not available at the moment. You may be able to try again."
            status={status}
            buttonText="Ok"
            navigateTo="/"
            center={true}
          />
        </Box>
      ) : null}
    </>
  );
};

export default TermsMenu;
