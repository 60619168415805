import React, { createContext } from "react";

const DealsContext = createContext();

export const DealsProvider = ({ children, value }) => {
  return (
    <DealsContext.Provider value={value}>{children}</DealsContext.Provider>
  );
};

export default DealsContext;
