import React from "react";
import { Box, Button } from "@mui/material";

const NftCard = ({ nft, selectNft }) => {
  const { nftUrl } = nft;
  return (
    <Button
      id="nft-card"
      sx={{
        borderRadius: "16px",
        border: 1,
        borderColor: "grey.accent3",
        bgcolor: "background.paper",
        display: "flex",
        flex: 1,
        p: "0px",
      }}
      onClick={() => selectNft(nft)}
    >
      <Box
        alt="Cover Image"
        component="img"
        src={nftUrl}
        sx={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          aspectRatio: "1/1",
          borderRadius: "16px",
        }}
        id="coverImage"
      />
    </Button>
  );
};

export default NftCard;
