import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useTranslation from "../../hooks/useTranslation";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as OnboardingIcon } from "../../assets/icons/onboarding.svg";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { authStatus } from "../../contexts/authContext/AuthContext";
import { trackOnboarding } from "../../mixpanel";

const Onboarding = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboarding" });
  const navigate = useNavigate();
  const { status, initialize } = useAuth();

  // useEffect(() => {
  //   trackOnboarding();
  // }, []);

  const handleSignup = () => {
    if (status === authStatus.ERROR) {
      initialize();
      navigate("/");
      return;
    }
    navigate("/terms-and-conditions");
  };

  return (
    <>
      <Helmet>
        <title>{t("ABC")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          pb: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <OnboardingIcon width="100%" height="100%" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "0 16px",
            }}
          >
            <Box sx={{ m: "16px 0" }}>
              <Typography 
                variant="h6">
                  {t("welcome_title")}
              </Typography>
            </Box>
            <Typography 
              variant="pharagraph" 
              sx={{marginBottom: '16px'}}>
                {t("first_paragraph")}
            </Typography>
            <Typography 
              variant="pharagraph">
                {t("second_paragraph")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          boxShadow: 2,
          position: "fixed",
          bottom: 0,
          p: "16px 16px 32px",
          backgroundColor: "background.paper",
        }}
      >
        <Button
          id="terms-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSignup}
        >
          <Typography variant="button" color="background.paper">
            {t("sign_up_btn")}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default Onboarding;
