import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import VouchersAPI from "../../../../../apiConfig/VouchersAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  skip: 0,
  vouchers: [],
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks

export const fetchAsyncVouchers = createAsyncThunk(
  "vouchers/fetchAsyncVouchers",
  async ({ skip, limit }, { rejectWithValue }) => {
    try {
      const response = await VouchersAPI.get(
        `/vouchers/mine?status=valid&skip=${skip}&limit=${limit}`
      );
      return {
        status: response.status,
        data: response.data.items,
        total: response.data.total,
        limit,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  }
);

//Slices
const VouchersSlice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    nextPage: (state, action) => {
      state.skip = state.skip + action.payload;
    },
    resetVouchers: (state, action) => {
      state.skip = 0;
      state.vouchers = [];
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncVouchers.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncVouchers.fulfilled, (state, { payload }) => {
        if (payload.data.length === 0 && state.vouchers.length === 0) {
          state.errorMessage = null;
          state.hasMore = false;
          state.status = status.EMPTY;
          return;
        }
        state.hasMore =
          state.skip / payload.limit <
          Math.floor(payload.total / payload.limit);
        state.vouchers = [...state.vouchers, ...payload.data];
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncVouchers.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { nextPage, resetVouchers } = VouchersSlice.actions;

export default VouchersSlice.reducer;
