import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import phoneNumberFormatter from "../../../utils/phoneNumberFormatter";

const ProfileSection = ({ user }) => {
  let language = localStorage.getItem("lang");
  language === "en-US" && (language = "en");

  const nameTH = user?.firstNameTH ? user?.firstNameTH : user?.firstNameEN;
  const nameEN = user?.firstNameEN ? user?.firstNameEN : user?.firstNameTH;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", m: "32px 16px" }}>
      <Avatar
        src={user?.profilePicture}
        sx={{ width: "60px", height: "60px", mr: "16px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6" color="grey.accent1" noWrap>
          {language === "th" ? nameTH : nameEN}
        </Typography>
        <Typography variant="body2" color="grey.accent1">
          {phoneNumberFormatter(user?.mobileNumber)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileSection;
