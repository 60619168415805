import React, { useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  HomeIconActive,
  WalletIcon,
  WalletIconActive,
  MarketplaceHomeActiveIcon,
  MarketPlaceHomeIcon,
  BurgerIcon,
  BurgerIconActive,
} from "../../assets/icons/menu-icons/MenuIcons";
import useNavigationHook from "../../hooks/useNavigation";

const icons = [
  {
    name: "ABC",
    src: <HomeIcon />,
    srcActive: <HomeIconActive />,
    href: "/",
  },
  {
    name: "ABC Wallet",
    src: <WalletIcon />,
    srcActive: <WalletIconActive />,
    href: "/wallet",
  },
  {
    name: "Marketplace",
    src: <MarketPlaceHomeIcon />,
    srcActive: <MarketplaceHomeActiveIcon />,
    href: "/marketplace",
  },
  {
    name: "Burger",
    src: <BurgerIcon />,
    srcActive: <BurgerIconActive />,
    href: "/settings",
  },
];

// Input the links for final urls should be the same with icons' href
export const activeIcon = (icon) => {
  switch (icon) {
    case "ABC":
      if (window.location.pathname === "/") {
        return true;
      }
      break;
    case "ABC Wallet":
      if (window.location.pathname.match(/\/wallet*/)) {
        return true;
      }
      break;
    case "Marketplace":
      if (window.location.pathname === "/marketplace") {
        return true;
      }
      break;
    case "Burger":
      if (window.location.pathname === "/settings") {
        return true;
      }
      break;
    default:
      return false;
  }
};

const Menu = ({ active }) => {
  const setActiveMenu = useNavigationHook();
  useEffect(() => {
    window.onpopstate = () => {
      setActiveMenu(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return active ? (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          paddingY: "14.54px",
          paddingX: "20px",
          backgroundColor: "background.paper",
        }}
      >
        {icons.map((icon, index) => {
          return (
            <IconButton
              component={NavLink}
              key={index}
              sx={{
                borderRadius: 24,
                height: 40,
                width: 72,
                backgroundColor: activeIcon(icon.name)
                  ? "secondary.pink.accent4"
                  : "",
                "&:hover": {
                  backgroundColor: activeIcon(icon.name)
                    ? "secondary.pink.accent4"
                    : "",
                },
                "&:active": {
                  backgroundColor: "primary.light",
                },
              }}
              to={icon.href}
            >
              {activeIcon(icon.name) ? icon.srcActive : icon.src}
            </IconButton>
          );
        })}
      </Box>
    </>
  ) : (
    <></>
  );
};

export default Menu;
