import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "../../redux/store/configureStore";
import { fetchAsyncTerms } from "../../redux/slices/terms/Terms";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import TermsAndConditionsSkeleton from "../../components/skeletonLoading/TermsAndConditionsSkeleton";
import GenericError from "../genericError/GenericError";
import { Helmet } from "react-helmet-async";
import AuthorizationError from "../authorizationError/AuthorizationError";
import useAuth from "../../hooks/useAuth";
import Prompt from "../../components/Prompt/Prompt";
import parse from "html-react-parser";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, terms, status } = useSelector((state) => state.terms);
  const { acceptTOS } = useAuth();
  const { t } = useTranslation("translation", {
    keyPrefix: "termsAndConditions",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleNavigate = () => navigate("/");
  React.useEffect(() => {
    dispatch(fetchAsyncTerms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatContent = (content) => {
    const removeOpeningTag = content.replace("<html>", "");
    const removeClosingTag = removeOpeningTag.replace("</html>", "");
    return parse(removeClosingTag);
  };

  return (
    <>
      <Helmet>
        <title>{t("ABC")}</title>
      </Helmet>
      {isLoading ? (
        <Box sx={{ backgroundColor: "background.paper", padding: "0 16px" }}>
          <TermsAndConditionsSkeleton />
        </Box>
      ) : status === 401 ? (
        <AuthorizationError />
      ) : status === 200 ? (
        terms && (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "background.paper",
                padding: "0 16px",
                mb: "90px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  // textAlign: "justify",
                  // textJustify: "inter-word",
                  inlineSize: "auto",
                  wordBreak: "break-word",
                }}
              >
                <Typography variant="body1">
                  {formatContent(terms.data.content)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                boxShadow: 2,
                position: "fixed",
                bottom: 0,
                p: "16px 16px 32px",
                backgroundColor: "background.paper",
              }}
            >
              <Button
                id="decline-btn"
                color="primary"
                variant="outlined"
                sx={{
                  borderRadius: 16,
                  width: "47%",
                  height: "50px",
                }}
                onClick={handleOpen}
              >
                <Typography variant="button">{t("Decline")}</Typography>
              </Button>
              <Button
                id="accept-btn"
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: 16,
                  width: "47%",
                  height: "50px",
                }}
                onClick={acceptTOS}
              >
                <Typography variant="button">{t("Accept")}</Typography>
              </Button>
            </Box>
          </Box>
        )
      ) : (
        <Box sx={{ width: "100%" }}>
          <GenericError
            title="Service is not available"
            body="Service is not available at the moment. You may be able to try again."
            status={status}
            buttonText="Ok"
            navigateTo="/"
            center={true}
          />
        </Box>
      )}
      <Prompt
        isVisible={open}
        onClose={handleNavigate}
        onClose2={handleClose}
        title={"Do you want to decline?"}
        subTitle={
          "If you decline, you will not be able to enable this service."
        }
        buttonText={"Decline"}
        buttonText2={"Not now"}
      />
    </>
  );
};

export default TermsAndConditions;
