import { Avatar, Box, Button, FormHelperText, Input, InputAdornment, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useNavigationHook from '../../../../hooks/useNavigation';
import useTranslation from '../../../../hooks/useTranslation';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Coins from "../../../../assets/icons/coins.svg";
import { paletteLight } from '../../../../theme/index'
import styled from '@emotion/styled';
import { useDispatch, useSelector } from '../../../../redux/store/configureStore';
import { fetchAsyncCoins, resetCoins, status as CoinsStatus } from '../../redux/slices/coins/Coins';
import { coinFormatter } from '../../../../utils/coinFormatter';
import useLoadingHook from '../../../../hooks/useLoadingHook';
import { fetchUserProfile, resetUserProfile, status as userProfileStatus } from '../../redux/slices/userProfile/userProfile';
import { resetTransferCoin } from '../../redux/slices/transferCoin/TransferCoin';
import Prompt from '../../../../components/Prompt/Prompt';
import { trackVisitTransferCoindSendPage } from '../../../../mixpanel';

const CoinCard = styled(Box)`
    background-color: ${paletteLight.background.paper};
    padding: 16px;
    border-radius: 16px;
    box-sizing: border-box;
`

const CoinInput = styled.input`
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    &:focus {
        border: none;
        outline: none;
    }
`

const regEx = new RegExp("^[0-9]*$");

function close() {

}

export const getUsername = (status, lang='en', items) => {
    const {firstNameEN, lastNameEN, firstNameTH, lastNameTH} = items
    if (status === userProfileStatus.LOADED && lang === 'en') {
        return `${firstNameEN} ${lastNameEN}`
    }
    if (status === userProfileStatus.LOADED && lang === 'th') {
        return `${firstNameTH} ${lastNameTH}`
    }
}

export const formatPhoneNumber = (phoneString) => {
    if (phoneString.length >= 7) {
        return phoneString.slice(0, 3) + '-' + phoneString.slice(3, 6) + '-' + phoneString.slice(6, 12)
    }
    if (phoneString.length >= 4) {
        return phoneString.slice(0, 3) + '-' + phoneString.slice(3, 6)
    }
    return phoneString
}


const TransferCoin = () => {
    const lang = localStorage.getItem("lang");
    const [promptOpen, setPromptOpen] = React.useState(false);
    const [promptError, setPromptError] = React.useState({
        title: null,
        subtitle: null,
        rawTitle: null,
        rawSubTitle: null,
        buttonText: null,
        onClose: close,
    });
    const [phoneNumber, setPhoneNumber] = useState({
        number: '',
        errorMessage: ''
    })
    const [coinAmount, setCoinAmount] = useState({
        number: '',
        errorMessage: ''
    })
    const [disableReviewButton, setDisableReviewButton] = useState(true)
    const navigate = useNavigate();
    const setActiveMenu = useNavigationHook();
    const dispatch = useDispatch();
    const progressScreen = useLoadingHook();
    const { coins, status } = useSelector((state) => state.coins);
    const userProfile = useSelector((state) => state.userProfile)
    const { t } = useTranslation("translation", {
        keyPrefix: "transferCoin",
    });

    useEffect(() => {
        setActiveMenu(false);
        if (status !== CoinsStatus.LOADING) {
            dispatch(fetchAsyncCoins());
        }
        trackVisitTransferCoindSendPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetTransferCoin())
            dispatch(resetCoins());
            dispatch(resetUserProfile())
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userProfile.status === userProfileStatus.LOADING) {
            progressScreen.setOpen(true)
            return
        }
        if (userProfile.status === userProfileStatus.LOADED) {
            progressScreen.setOpen(false)
            if (userProfile.profile.items.length > 0) {
                navigate('/transfer-review', {
                    state: {
                        transfer: {
                            amount: coinAmount.number,
                            phoneNumber: phoneNumber.number,
                            abcChainAccount: userProfile.profile.items[0].abcChainAccount,
                            fromWalletAddress: coins?.account,
                            userName: getUsername(
                                userProfile.status, 
                                lang, 
                                userProfile.profile.items[0]
                            )
                        }
                    }
                })
            } else {
                setPhoneNumber({ ...phoneNumber, errorMessage: t('This mobile number is not registered for ABC Wallet service') })
                setPromptError({
                    title: 'Mobile number not found',
                    subtitle: 'This mobile number is not registered for ABC Wallet service',
                    rawTitle: null,
                    rawSubTitle: null,
                    buttonText: 'OK',
                    onClose: () => setPromptOpen(false),
                })
                setPromptOpen(true)
            }
            return
        }
        if (userProfile.status === userProfileStatus.ERROR) {
            progressScreen.setOpen(false)
            setPhoneNumber({ ...phoneNumber, errorMessage: t('Invalid mobile number') })
            setPromptError({
                title: 'Oops, there was an issue',
                subtitle: "Please try again",
                rawTitle: null,
                rawSubTitle: null,
                buttonText: 'OK',
                onClose: () => setPromptOpen(false),
            })
            setPromptOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile.status])

    useEffect(() => {
        // Use this useEffect for disable or enable the review button.
        if (
            !phoneNumber.errorMessage
            && !coinAmount.errorMessage
            && phoneNumber.number
            && phoneNumber.number.toString().replaceAll('-', '').length === 10
            && +coinAmount.number > 0
        ) {
            setDisableReviewButton(false)
        } else {
            setDisableReviewButton(true)
        }
    }, [phoneNumber, coinAmount])


    const handlePhoneInputChange = (e) => {
        const { value } = e.target
        const justPhoneString = value.toString().replaceAll('-', '')
        if(!justPhoneString) {
            setPhoneNumber({number: justPhoneString, errorMessage: ''})
            return 
        }
        if (justPhoneString.length > 10) {
            return
        }
        if (!regEx.test(justPhoneString)) {
            setPhoneNumber({ number: phoneNumber.number, errorMessage: t('Invalid mobile number') })
            return
        }
        if (justPhoneString[0] !== '0') {
            setPhoneNumber({ number: formatPhoneNumber(justPhoneString), errorMessage: t('Invalid mobile number') })
            return
        }
        setPhoneNumber({ number: formatPhoneNumber(justPhoneString), errorMessage: '' })
    }

    const handlePhoneInputBlur = (e) => {
        const {value} = e.target
        const justPhoneString = value.toString().replaceAll('-', '')
        if (justPhoneString.length < 10) {
            setPhoneNumber({ number: phoneNumber.number, errorMessage: t('Invalid mobile number') })  
        }
    }

    const handleCoinChange = (e) => {
        const { value } = e.target
        const { balance } = coins
        if (!regEx.test(value) || value === '0') {
            return
        }
        if (+value > +balance) {
            setCoinAmount({ number: value, errorMessage: t("Insufficient balance") })
            return
        }
        setCoinAmount({ number: value, errorMessage: '' })
    }

    const handleReviewClick = () => {
        dispatch(fetchUserProfile(phoneNumber.number.toString().replaceAll('-', '')))
    }

    return (
        <>
            <Helmet>
                <title>{t('Send')}</title>
            </Helmet>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: "32px 16px 0",
                    backgroundColor: "grey.accent4",
                    height: "calc(100vh - 50px)",
                }}
            >
                <Typography variant='subtitle1' fontWeight='500' sx={{ marginBottom: '8px' }}>{t("You send")}</Typography>
                <CoinCard>
                    <Typography>ABC</Typography>
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Avatar
                            id='img_abcLogo_ConvertLoyaltyPointPage'
                            sx={{
                                height: "40px",
                                width: "40px",
                                mt: "8px",
                                mr: '48px'
                            }}
                            src={Coins}
                        />

                        <Input
                            id="input-coin-amount"
                            error={coinAmount.errorMessage ? true : false}
                            color={!coinAmount.errorMessage && "grey"}
                            value={coinAmount.number}
                            inputProps={{
                                "data-testid": "input-coin-amount",
                                inputMode: "numeric",
                                style: {
                                    color: coinAmount.errorMessage ? "#E50914" : "#141312",
                                    fontWeight: 600
                                },
                            }}
                            placeholder={"0"}
                            sx={{ fontSize: "32px" }}
                            onChange={handleCoinChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Typography
                                        id='lbl_maxTruePointBalance_CoinTransferPage'
                                        variant="subtitle"
                                        color={"primary"}>
                                        MAX
                                    </Typography>
                                </InputAdornment>
                            }
                        />
                    </Box>
                    <Box sx={{ mt: '4px' }}>
                        <FormHelperText
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Typography
                                id='lbl_truePointBalance_ConvertLoyaltyPointPage'
                                color={coinAmount.errorMessage ? "error" : "grey.accent2"}
                                variant="helpingText"
                            >
                                {
                                    status === CoinsStatus.LOADING
                                        ? <Skeleton
                                            animation="wave"
                                            height={"20px"}
                                            width="100px"
                                            id="coins-loading"
                                        />
                                        : status === CoinsStatus.ERROR
                                            ? t("Unable to retrieve balance")
                                            : coinAmount.errorMessage
                                                ? coinAmount.errorMessage
                                                : status === CoinsStatus.LOADED
                                                    ? (`${t("Balance")} ${coinFormatter(coins.balance)}`)
                                                    : null
                                }
                            </Typography>
                        </FormHelperText>
                    </Box>
                </CoinCard>
                <Typography variant='subtitle1' fontWeight='500' sx={{ margin: '24px 0 8px' }}>{t("To")}</Typography>
                <CoinCard
                    sx={{
                        border: `1px solid ${phoneNumber.errorMessage ? paletteLight.error.main : 'transparent'}`
                    }}
                >
                    <CoinInput
                        id='input-phonenumber'
                        data-testid="input-phonenumber"
                        placeholder={t('Enter mobile no.')}
                        value={phoneNumber.number}
                        onChange={handlePhoneInputChange}
                        onBlur={handlePhoneInputBlur}
                        style={{
                            color: phoneNumber.errorMessage 
                                ? paletteLight.error.main 
                                : paletteLight.grey.accent1
                        }}
                    />
                </CoinCard>
                <Typography
                    data-testid="phoneNumber-helping-text"
                    variant="helpingText"
                    color={phoneNumber.errorMessage ? "error" : 'grey.accent2'}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: '8px'
                    }}>
                    {phoneNumber.errorMessage || t("You can only send ABC coins to other ABC Wallet users")}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    position: "fixed",
                    bottom: 0,
                    p: "16px 16px 32px",
                    backgroundColor: "grey.accent4",
                }}
            >
                <Button
                    data-testid="review-btn"
                    color="primary"
                    disabled={disableReviewButton}
                    variant="contained"
                    sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
                    fullWidth
                    onClick={handleReviewClick}
                >
                    <Typography variant="button">{t("Review")}</Typography>
                </Button>
            </Box>
            <Prompt
                isVisible={promptOpen}
                icon={"error"}
                onClose={promptError.onClose}
                title={promptError.title}
                subTitle={promptError.subtitle}
                rawTitle={promptError.rawTitle}
                rawSubTitle={promptError.rawSubTitle}
                buttonText={promptError.buttonText}
                keyPrefix={"transferCoin"}
            />
        </>
    )
}

export default TransferCoin