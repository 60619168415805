import React, { useCallback, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import NftAsset from "../../components/nftAsset/NftAsset";
import { trackNftView } from "../../../../mixpanel/nft";
import useAuth from "../../../../hooks/useAuth";
import { fullnameFormatterWithLang } from "../../../../utils/nameFormatter";
import phoneNumberFormatter from "../../../../utils/phoneNumberFormatter";

const NftDetail = () => {
  const { collection, token, allTokens } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  const { user } = useAuth();
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();

  const getUserFullname = useCallback(() => {
    return fullnameFormatterWithLang({
      firstNameEN: user?.firstNameEN,
      middleNameEN: user?.middleNameEN,
      lastNameEN: user?.lastNameEN,
      firstNameTH: user?.firstNameTH,
      middleNameTH: user?.middleNameTH,
      lastNameTH: user?.lastNameTH,
    });
  }, [user]);

  useEffect(() => {
    setActiveMenu(false);
  }, [setActiveMenu]);

  useEffect(() => {
    let nftBalance = 0;
    Object.keys(allTokens).forEach(key => {
      nftBalance = nftBalance + allTokens[key].length;
    });
    trackNftView(nftBalance, collection.name);
  }, [collection, allTokens]);

  const handleOnClickCancel = () => {
    navigate("/wallet/nfts");
  };

  const handleOnClickSend = () => {
    navigate("/wallet/nfts/my-collection/send-nft", {
      state: {
        collection,
        token,
      },
    });
  };

  const getCreator = () => {
    const creator = collection?.metadata?.attributes?.find(
      item => item.trait_type === "creator",
    );
    return creator?.value || "";
  };

  return (
    <>
      <Helmet>
        <title>{t("My Collection")}</title>
      </Helmet>
      <Box sx={{ mt: "32px", mx: "16px", mb: "44px" }}>
        <Box sx={{ mb: "24px" }}>
          <NftAsset token={token} />
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography variant="h6">{token.metadata?.name}</Typography>
          <Typography variant="body1">{`${t(
            "by",
          )} ${getCreator()}`}</Typography>
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography variant="h6">{t("Description")}</Typography>
          <Typography variant="body1">{token.metadata?.description}</Typography>
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography variant="h6">{t("Collection name")}</Typography>
          <Typography variant="body1">{collection.name}</Typography>
        </Box>
        <Box sx={{ mb: "48px" }}>
          <Typography variant="h6">{t("Owner")}</Typography>
          <Typography variant="body1">{getUserFullname()}</Typography>
          <Typography variant="body1">
            {phoneNumberFormatter(user?.mobileNumber)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            sx={{ borderRadius: 16, width: "47%", height: "50px" }}
            onClick={handleOnClickCancel}
          >
            <Typography variant="button">{t("CANCEL")}</Typography>
          </Button>
          <Button
            color="primary"
            variant="contained"
            sx={{ borderRadius: 16, width: "47%", height: "50px" }}
            onClick={handleOnClickSend}
          >
            <Typography variant="button">{t("SEND")}</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NftDetail;
