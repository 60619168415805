import { Box, Typography, Modal } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import LoadingIcon from "../../assets/icons/loading.svg";
import useTranslation from "../../hooks/useTranslation";

const LoadingAnimationBox = styled(Box)`
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: animation-61bdi0;
`;

const LoadingPrompt = ({
  image = "",
  title,
  rawTitle,
  keyPrefix = "Prompt",
  isVisible,
  titleSx,
  imageContainerSx,
  imageSx,
}) => {
  const { t } = useTranslation("translation", { keyPrefix });
  return (
    <Modal
      open={isVisible}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          width: "100%",
          minHeight: "144px",
          m: "16px",
          justifyContent: "space-between",
          borderRadius: "16px",
          p: "24px",
          overflowY: "auto",
          maxHeight: "100%",
          alignItems: "center",
        }}
      >
        <LoadingAnimationBox
          sx={{
            marginBottom: "24px",
            width: "48px",
            height: "48px",
            ...imageContainerSx,
          }}
        >
          <Box
            id="img_logoPopup"
            component="img"
            src={image || LoadingIcon}
            sx={{
              width: "48px",
              height: "48px",
              ...imageSx,
            }}
          />
        </LoadingAnimationBox>
        <Typography
          id="lbl_titlePopup"
          variant="body2"
          sx={{
            ...titleSx,
          }}
        >
          {title ? t(title) : rawTitle || ""}
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingPrompt;
