import axios from "axios";
// import MockAdapter from "axios-mock-adapter";
// import mockApis from "../__fakeAPI__/customerApi";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const NftsAPI = axios.create({
  baseURL: apiEndpoint.nfts,
  headers: {
    "content-type": "application/json",
  },
});
NftsAPI.interceptors.request.use(refreshInterceptor);

// mockApis(new MockAdapter(NftsAPI, { delayResponse: 2000 }));

export default NftsAPI;
