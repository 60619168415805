import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProfileAPI from "../../../../../apiConfig/ProfileAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  profile: null,
  status: status.INITIAL,
  errorMessage: null,
};

export const fetchUserProfile = createAsyncThunk(
  "transferCoin/fetchUserProfile",
  async (mobileNumber, {rejectWithValue}) => {
    try {
      const response = await ProfileAPI.get(`profiles?mobileNumber=${mobileNumber}`) 
      return {
        status: response.status,
        data: response.data
      }
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data
      })
    }
  }
)

const UserProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    resetUserProfile: (state, action) => {
      state.profile = null
      state.status = status.INITIAL
      state.errorMessage = null
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserProfile.pending, (state) => {
      state.errorMessage = null
      state.status = status.LOADING
    })
    .addCase(fetchUserProfile.fulfilled, (state, {payload}) => {
      if (payload.data === undefined) {
        state.status = status.ERROR;
        return;
      }
      state.profile = payload.data
      state.status = status.LOADED
      state.errorMessage = null
      return
    })
    .addCase(fetchUserProfile.rejected, (state, {payload}) => {
      if (payload === undefined) {
        state.status = status.ERROR;
        return;
      }
      state.errorMessage = payload.data;
      state.status = status.ERROR;
    })
  }
})

export const { resetUserProfile } = UserProfileSlice.actions

export default UserProfileSlice.reducer