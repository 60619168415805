import React from "react";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import { paletteLight } from "../../../../../../theme/index";

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: paletteLight.grey.accent3,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${paletteLight.grey.accent3}`,
    },
    input: {
      padding: "10px 12px",
      caretColor: paletteLight.grey.accent1,
      borderRadius: "100px",
    },

    height: "54px",
    width: "100%",
    borderRadius: "50px",
    background: paletteLight.grey.accent3,
  },
  width: "100%",
  marginBottom: "5px",
});

const NftWalletInputField = ({ handleChange, value }) => {
  const onChange = (e) => handleChange(e.target.value);
  return (
    <CssTextField
      id="nft-wallet-field"
      value={value}
      onChange={onChange}
      autoComplete="off"
      type="text"
    />
  );
};

export default NftWalletInputField;
